import React from 'react';
import { useState, useEffect } from 'react';
import { useUI } from '../../../context/ui';
import { useUser } from '../../../context/user';
import { showMessage } from '../../../actions/ui';
import config from '../../../config';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { Close } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import ApiService from '../../../actions/apiService';
import InputField from '../../components/InputField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import FunctionalIFrameComponent from './Iframe';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectField from '../../components/SelectField';
import { SelectChangeEvent } from '@mui/material/Select';
const DOMPurify = require('dompurify');
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const Input = styled('input')({
  display: 'none'
});
interface FormData {
  id: string | null;
  name: string;
  content: {
    web: string;
    mobile: string;
  };
  link: string;
  file: any;
  client: string;
  bannerType: string;
}

interface IError {
  server: string;
  name: boolean;
  nameMessage: string;
  link: boolean;
  linkMessage: string;
  client: boolean;
  clientMessage: string;
  content: boolean;
  contentMessage: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  const params = useParams();
  const navigate = useNavigate();
  const [banner, setBanner] = useState<{
    loading: boolean;
    details: any;
  }>({
    loading: true,
    details: {
      name: '',
      link: '',
      content: {
        web: '',
        mobile: ''
      },
      client: '',
      image: {
        web: '',
        mobile: ''
      }
    }
  });

  const [state, setState] = useState<FormData>({
    id: null,
    name: '',
    link: '',
    content: {
      web: '',
      mobile: ''
    },
    client: '',
    bannerType: 'html',
    file: {
      web: undefined,
      mobile: undefined
    }
  });

  const [list, setList] = useState({
    loading: true,
    rows: [],
    pageSize: 1000,
    page: 1
  });

  const [errors, setErrors] = useState<IError>({
    server: '',
    name: false,
    nameMessage: '',
    link: false,
    linkMessage: '',
    client: false,
    clientMessage: '',
    content: false,
    contentMessage: ''
  });

  const [expanded, setExpanded] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const sanitizedData = (data: any) => ({
    __html: DOMPurify.sanitize(data, { WHOLE_DOCUMENT: true })
  });

  const getItems = async () => {
    try {
      const query: QueryParams = {};
      const [json, clients] = await Promise.all([
        ApiService.get(userContext.state.token, query, `banner/${params.id}`),
        ApiService.get(userContext.state.token, query, `client`)
      ]);

      setBanner((prev) => ({
        ...prev,
        loading: false,
        details: json ? json : {}
      }));

      setList((prev) => ({
        ...prev,
        loading: false,
        rows: clients ? clients.docs.map((item: any) => ({ label: item.name, value: item._id })) : [],
        rowCount: clients ? clients.totalDocs : 0
      }));

      setState((prev) => ({
        ...prev,
        name: json.name,
        link: json.link,
        content: json.content,
        client: json.client,
        bannerType: json.image?.web || json.image?.mobile ? 'image' : 'html',
        file: json.image ? json.image : undefined
      }));
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: FormData) => ({ ...prev, bannerType: (event.target as HTMLInputElement).value }));
  };

  const save = async () => {
    setErrors((prev) => ({ ...prev, uniqueId: false, uniqueIdMessage: '', server: '' }));
    //validation
    let isValid = true;
    if (!state.name) {
      setErrors((prev) => ({ ...prev, name: true, nameMessage: 'Name is required' }));
      isValid = false;
    }
    if (!state.client) {
      setErrors((prev) => ({ ...prev, client: true, clientMessage: 'Client is required' }));
      isValid = false;
    }

    const req: any = {
      name: state.name,
      content: state.content,
      client: state.client
    };
    if (state.bannerType === 'html' && !state.content.web && !state.content.mobile) {
      setErrors((prev) => ({ ...prev, content: true, contentMessage: 'Content is required' }));
      isValid = false;
    }

    if (state.bannerType === 'image' && !state.file.web && !state.file.mobile) {
      setErrors((prev) => ({ ...prev, content: true, contentMessage: 'Image is required' }));
      isValid = false;
      delete req.content;
    }
    if (state.bannerType === 'image' && !state.link) {
      setErrors((prev) => ({ ...prev, link: true, linkMessage: 'Link is required' }));
      isValid = false;
    }
    if (state.bannerType === 'image') {
      delete req.content;
    }

    if (state.bannerType === 'image') {
      req.link = state.link;
    }

    if (!isValid) {
      return;
    }

    try {
      setLoading(true);
      const response = await ApiService.put(userContext.state.token, req, `banner/${params.id}`);
      console.log({ state });
      if (state.bannerType === 'image' && (state.file.web || state.file.mobile)) {
        const data = new FormData();
        data.append('image', state.file.web);
        data.append('mobile', state.file.mobile);
        data.append('bannerId', `${params.id}`);
        await ApiService.postFile(userContext.state.token, data, 'banner/upload');
      }

      showMessage(UIContext)('Successfully updated!', 'success');
      getItems();
      return response;
    } catch (err) {
      setErrors((prev) => ({ ...prev, server: err.message }));
      return null;
    } finally {
      setLoading(false);
    }
  };

  const deleteBanner = async () => {
    const isExecuted = confirm('Are you sure to execute this action?');
    let json: IAPIResponse | null = null;
    if (isExecuted) {
      json = await ApiService.deleteService(userContext.state.token, `banner/${params.id}`);
      if (json) {
        navigate('/');
      }
    }
    return null;
  };

  const handleChangeClient = async (event: SelectChangeEvent) => {
    setState((prv) => ({ ...prv, [event.target.name]: event.target.value }));
    // const query: QueryParams = {};
    // const banner = await  ApiService.get(userContext.state.token,query, `banner/${event.target.value}`);
    // if(banner){
    //     setState((prev) => ({...prev,selectedBanner:banner}));
    // }
  };

  // @ts-ignore
  return (
    <>
      {banner.loading && <h1>Loading...</h1>}
      {!banner.loading && (
        <Card sx={{ maxWidth: '100%' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
                B
              </Avatar>
            }
            title={banner?.details?.name || ''}
            subheader={`Banner Id: ${banner.details._id}`}
            action={
              <IconButton onClick={() => navigate('/banner')}>
                <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
              </IconButton>
            }></CardHeader>

          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
                  <InputField
                    handleInputChange={(e: any) => setState((prev) => ({ ...prev, name: e.target.value }))}
                    value={state.name}
                    label={'Banner name'}
                    name={'name'}
                    error={errors.name}
                    message={errors.nameMessage}
                    handleErrorChange={() => setErrors((prev) => ({ ...prev, name: false, nameMessage: '' }))}
                  />

                  <SelectField
                    value={state.client}
                    name={'client'}
                    handleChangeWebsite={handleChangeClient}
                    label='Ad client'
                    list={list.rows}
                    error={errors.client}
                    message={errors.clientMessage}
                    handleErrorChange={() =>
                      setErrors((prev) => ({ ...prev, client: false, clientMessage: '' }))
                    }
                  />
                  {state.bannerType === 'image' && (
                    <InputField
                      handleInputChange={(e: any) => setState((prev) => ({ ...prev, link: e.target.value }))}
                      value={state.link}
                      label={'Link'}
                      name={'link'}
                      error={errors.link}
                      message={errors.linkMessage}
                      handleErrorChange={() =>
                        setErrors((prev) => ({ ...prev, link: false, linkMessage: '' }))
                      }
                    />
                  )}

                  <FormControl sx={{ marginLeft: '8px' }}>
                    <FormLabel id='demo-radio-buttons-group-label'>Banner type</FormLabel>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      name='radio-buttons-group'
                      value={state.bannerType}
                      onChange={handleChange}>
                      <FormControlLabel value='html' control={<Radio />} label='Embed code' />
                      <FormControlLabel value='image' control={<Radio />} label='Image' />
                    </RadioGroup>
                  </FormControl>

                  {state.bannerType === 'html' && (
                    <>
                      <FormControl>
                        <FormLabel id='textarea-label-web'>Content - desktop</FormLabel>
                        <TextareaAutosize
                          id='textarea-label-web'
                          aria-label='minimum height'
                          placeholder='Embed code'
                          minRows={5}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setState((prev: FormData) => ({
                              ...prev,
                              content: { ...prev.content, web: e.target.value }
                            }))
                          }
                          defaultValue={state.content?.web}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel id='textarea-label-mobile'>Content - mobile</FormLabel>
                        <TextareaAutosize
                          id='textarea-label-mobile'
                          aria-label='minimum height'
                          placeholder='Embed code'
                          minRows={5}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setState((prev: FormData) => ({
                              ...prev,
                              content: { ...prev.content, mobile: e.target.value }
                            }))
                          }
                          defaultValue={state.content?.mobile}
                        />
                      </FormControl>
                    </>
                  )}
                  {state.bannerType === 'image' && (
                    <>
                      <label htmlFor='contained-button-file'>
                        <Input
                          accept='image/*'
                          id='contained-button-file'
                          type='file'
                          onChange={(event: any) =>
                            setState((prev) => ({
                              ...prev,
                              file: {
                                ...prev.file,
                                web: event.target.files[0]
                              }
                            }))
                          }
                        />
                        <Button variant='contained' component='span'>
                          Change desktop image
                        </Button>
                        <Typography>{state?.file?.web ? state.file.web?.name : ''}</Typography>
                      </label>
                      <label htmlFor='contained-button-file-mobile'>
                        <Input
                          accept='image/*'
                          id='contained-button-file-mobile'
                          type='file'
                          onChange={(event: any) =>
                            setState((prev) => ({
                              ...prev,
                              file: {
                                ...prev.file,
                                mobile: event.target.files[0]
                              }
                            }))
                          }
                        />
                        <Button variant='contained' component='span'>
                          Change mobile image
                        </Button>
                        <Typography>{state?.file?.mobile ? state.file.mobile?.name : ''}</Typography>
                      </label>
                    </>
                  )}
                  {errors.content ? (
                    <FormHelperText sx={{ color: '#d32f2f' }}>{errors.contentMessage}</FormHelperText>
                  ) : null}

                  <FormControl>
                    <Grid container justifyContent='end' columnGap='5px'>
                      <Grid item>
                        <Button
                          sx={{ alignSelf: 'flex-start', mt: '8px', minWidth: '80px' }}
                          color={'error'}
                          variant='outlined'
                          startIcon={<DeleteIcon />}
                          onClick={() => deleteBanner()}>
                          Delete
                        </Button>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          onClick={save}
                          disabled={loading}
                          loading={loading}
                          variant='outlined'
                          sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
                          Save changes
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <Button variant='text' onClick={handleExpandClick}>
              Preview banner
            </Button>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
              color={'info'}>
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent>
              <Typography>Desktop</Typography>
              <Box>
                <FunctionalIFrameComponent title='functional-iframe'>
                  {banner?.details?.image?.web && (
                    <img
                      width={'100%'}
                      src={`${config.apiUrl}/api/banner/${banner?.details?._id}/content?type=web`}
                    />
                  )}
                  {banner?.details?.content?.web && (
                    <div dangerouslySetInnerHTML={sanitizedData(banner?.details?.content?.web)} />
                  )}
                </FunctionalIFrameComponent>
              </Box>
              <Typography>Mobile</Typography>
              <Box>
                <FunctionalIFrameComponent title='functional-iframe'>
                  {banner?.details?.image?.mobile && (
                    <img
                      width={'100%'}
                      src={`${config.apiUrl}/api/banner/${banner?.details?._id}/content?type=mobile`}
                    />
                  )}
                  {banner?.details?.content?.mobile && (
                    <div dangerouslySetInnerHTML={sanitizedData(banner?.details?.content?.mobile)} />
                  )}
                </FunctionalIFrameComponent>
              </Box>
            </CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
};
