import React from 'react';
import { useState, useEffect } from 'react';
import { useUI } from '../../../context/ui';
import { useUser } from '../../../context/user';
import { showMessage } from '../../../actions/ui';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { Close } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useParams, Link } from 'react-router-dom';
import ApiService from '../../../actions/apiService';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@mui/material';
import Title from '../../components/Title';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

interface IWebsite {
  loading: boolean;
  advertising: any[];
  locations: any[];
  details: any[];
  url: string;
  name: string;
  nrViews: number;
  nrClicks: number;
}

export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  const params = useParams();
  const navigate = useNavigate();
  const [website, setWebsite] = useState<IWebsite>({
    loading: true,
    advertising: [],
    locations: [],
    details: [],
    name: '',
    nrViews: 0,
    nrClicks: 0,
    url: ''
  });

  const getItems = async () => {
    try {
      const query: QueryParams = {};

      const json = await ApiService.get(userContext.state.token, query, `website/${params.id}`);
      setWebsite((prev: IWebsite) => ({
        ...prev,
        loading: false,
        advertising: json.advertising,
        locations: json.locations,
        details: json.details,
        name: json.name,
        nrViews: json.nrViews,
        nrClicks: json.nrClicks,
        url: json.url
      }));
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
  };

  useEffect(() => {
    getItems().catch((err) => console.log(err));
  }, []);

  return (
    <Card sx={{ maxWidth: '100%' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
            W
          </Avatar>
        }
        title={website.name || ''}
        subheader={
          <a href={website.url} target='_blank'>
            Open Site
          </a>
        }
        action={
          <IconButton onClick={() => navigate('/website')}>
            <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
          </IconButton>
        }
      />

      <CardContent>
        <div style={{ display: 'flex' }}>
          <Grid item xs={6}>
            <Typography variant='h5' component='h5'>
              Total views: {website.nrViews}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h5' component='h5'>
              Total clicks: {website.nrClicks}
            </Typography>
          </Grid>
        </div>

        <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>Website locations: ({website.locations.length})</Title>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Location name</TableCell>
                    <TableCell>Total Views</TableCell>
                    <TableCell>Total Clicks</TableCell>
                    <TableCell>See details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {website.locations?.map((row) => (
                    <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component='th' scope='row'>
                        {row.description}{' '}
                      </TableCell>
                      <TableCell>{row.nrViews}</TableCell>
                      <TableCell>{row.nrClicks}</TableCell>
                      <Link to={'/location/' + row._id}>
                        <IconButton>
                          <VisibilityIcon />
                        </IconButton>
                      </Link>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Title>Associated advertisements: ({website.advertising.length})</Title>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Advertising name</TableCell>
                    <TableCell>Total Views</TableCell>
                    <TableCell>Total Clicks</TableCell>
                    <TableCell>See details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {website.advertising?.map((row) => {
                    return (
                      <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>
                          {row.name}
                        </TableCell>
                        <TableCell>{row.nrViews}</TableCell>
                        <TableCell>{row.nrClicks}</TableCell>
                        <Link to={'/advertising/' + row._id}>
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>
                        </Link>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
