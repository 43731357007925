import React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { Close } from '@mui/icons-material';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import InputField from '../components/InputField';
import ApiService from '../../actions/apiService';
import SelectField from '../components/SelectField';
import { SelectChangeEvent } from '@mui/material/Select';

const Input = styled('input')({
  display: 'none'
});
type FormData = {
  id: string | null;
  name: string;
  content: object;
  link: string;
  client: string;
  defaultValue?: any;
};

type IError = {
  server: string;
  name: boolean;
  nameMessage: string;
  link: boolean;
  linkMessage: string;
  client: boolean;
  clientMessage: string;
  content: boolean;
  contentMessage: string;
};

export default (props: { close: (reloadList: boolean) => void }) => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;

  const [list, setList] = useState({
    loading: true,
    rows: [],
    pageSize: 1000,
    page: 1
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      id: null,
      name: '',
      link: '',
      content: {
        web: '',
        mobile: ''
      },
      client: '',
      defaultValue: { label: '', value: '' }
    }));
    setErrors((prev) => ({
      ...prev,
      server: '',
      link: false,
      linkMessage: '',
      website: false,
      websiteMessage: ''
    }));
    getItems();
  }, []);

  const getItems = async () => {
    let json: IAPIResponse | null = null;
    try {
      const query: QueryParams = {};

      json = await ApiService.get(userContext.state.token, query, `client`);
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
    setList((prev) => ({
      ...prev,
      loading: false,
      rows: json ? json.docs.map((item: any) => ({ label: item.name, value: item._id })) : [],
      rowCount: json ? json.totalDocs : 0
    }));
  };

  const [value, setValue] = React.useState('html');

  const [file, setFile] = useState<any>(undefined);
  const [fileMobile, setFileMobile] = useState<any>(undefined);
  console.log({ fileMobile });

  const [state, setState] = useState<FormData>({
    id: null,
    name: '',
    link: '',
    content: {
      web: '',
      mobile: ''
    },
    client: '',
    defaultValue: { label: '', value: '' }
  });

  const [errors, setErrors] = useState<IError>({
    server: '',
    name: false,
    nameMessage: '',
    link: false,
    linkMessage: '',
    client: false,
    clientMessage: '',
    content: false,
    contentMessage: ''
  });

  const [loading, setLoading] = useState(false);

  const save = async () => {
    setErrors((prev) => ({ ...prev, uniqueId: false, uniqueIdMessage: '', server: '' }));
    //validation
    let isValid = true;
    if (!state.name) {
      setErrors((prev) => ({ ...prev, name: true, nameMessage: 'Name is required' }));
      isValid = false;
    }

    if (!state.client) {
      setErrors((prev) => ({ ...prev, client: true, clientMessage: 'Name is required' }));
      isValid = false;
    }

    if (value === 'html' && !state.content) {
      setErrors((prev) => ({ ...prev, content: true, contentMessage: 'Content is required' }));
      isValid = false;
    }

    if (value === 'image' && !file && !fileMobile) {
      setErrors((prev) => ({
        ...prev,
        content: true,
        contentMessage: 'Should have one image!'
      }));
      isValid = false;
    }
    if (value === 'image' && !state.link) {
      setErrors((prev) => ({ ...prev, link: true, linkMessage: 'Link is required' }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const req: any = {
      name: state.name,
      content: state.content,
      client: state.client
    };
    if (value === 'image') {
      req.link = state.link;
    }

    try {
      setLoading(true);

      //is new
      const response = await ApiService.post(userContext.state.token, req, 'banner');
      if (value === 'image') {
        const data = new FormData();
        data.append('image', file);
        data.append('mobile', fileMobile);
        data.append('bannerId', response._id);
        await ApiService.postFile(userContext.state.token, data, '/banner/upload');
      }
      props.close(true);
      showMessage(UIContext)('Successfully added!', 'success');
      return response;
    } catch (err) {
      setErrors((prev) => ({ ...prev, server: err.message }));
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleChangeClient = async (event: SelectChangeEvent) => {
    setState((prv) => ({ ...prv, [event.target.name]: event.target.value }));
    // const query: QueryParams = {};
    // const banner = await  ApiService.get(userContext.state.token,query, `banner/${event.target.value}`);
    // if(banner){
    //     setState((prev) => ({...prev,selectedBanner:banner}));
    // }
  };

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '100%' }
      }}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography component='h2' variant='h6' sx={{ color: '#222326' }}>
          {state.id ? 'Update banner' : 'Create banner'}
        </Typography>
        <IconButton onClick={() => props.close(false)}>
          <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
        </IconButton>
      </Box>
      <Box>{errors.server ? <Alert severity='error'>{errors.server}</Alert> : null}</Box>
      <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
        <InputField
          handleInputChange={(e: any) => setState((prev) => ({ ...prev, name: e.target.value }))}
          value={state.name}
          label={'Banner name'}
          name={'name'}
          error={errors.name}
          message={errors.nameMessage}
          handleErrorChange={() => setErrors((prev) => ({ ...prev, name: false, nameMessage: '' }))}
        />

        <SelectField
          value={state.client}
          name={'client'}
          handleChangeWebsite={handleChangeClient}
          label='Ad client'
          list={list.rows}
          error={errors.client}
          message={errors.clientMessage}
          handleErrorChange={() => setErrors((prev) => ({ ...prev, client: false, clientMessage: '' }))}
        />

        {value === 'image' && (
          <InputField
            handleInputChange={(e: any) => setState((prev) => ({ ...prev, link: e.target.value }))}
            value={state.link}
            label={'Link'}
            name={'link'}
            error={errors.link}
            message={errors.linkMessage}
            handleErrorChange={() => setErrors((prev) => ({ ...prev, link: false, linkMessage: '' }))}
          />
        )}
        <FormControl sx={{ marginLeft: '8px' }}>
          <FormLabel id='demo-radio-buttons-group-label'>Banner type</FormLabel>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            name='radio-buttons-group'
            value={value}
            onChange={handleChange}>
            <FormControlLabel value='html' control={<Radio />} label='Embed code' />
            <FormControlLabel value='image' control={<Radio />} label='Image' />
          </RadioGroup>
        </FormControl>
        <FormControl>
          {value === 'html' && (
            <>
              <FormLabel id='textarea-label'>Content - desktop</FormLabel>
              <TextareaAutosize
                aria-label='minimum height'
                placeholder='Embed code'
                minRows={5}
                style={{ width: '100%' }}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    content: {
                      ...prev.content,
                      web: e.target.value
                    }
                  }))
                }
              />
              <hr />

              <FormLabel id='textarea-label'>Content - mobile</FormLabel>
              <TextareaAutosize
                aria-label='minimum height'
                placeholder='Embed code'
                minRows={5}
                style={{ width: '100%' }}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    content: {
                      ...prev.content,
                      mobile: e.target.value
                    }
                  }))
                }
              />
            </>
          )}
          {value === 'image' && (
            <>
              <label htmlFor='contained-button-file'>
                <Input
                  accept='image/*'
                  id='contained-button-file'
                  type='file'
                  onChange={(event: any) => setFile(event.target.files[0])}
                />

                <Button variant='contained' component='span'>
                  Upload - desktop
                </Button>
                <Typography>{file ? file?.name : ''}</Typography>
              </label>
              <hr />
              <label htmlFor='contained-button-file-mobile'>
                <Input
                  accept='image/*'
                  id='contained-button-file-mobile'
                  type='file'
                  onChange={(event: any) => setFileMobile(event.target.files[0])}
                />

                <Button variant='contained' component='span'>
                  Upload - mobile
                </Button>
                <Typography>{fileMobile ? fileMobile?.name : ''}</Typography>
              </label>
            </>
          )}
          {errors.content ? (
            <FormHelperText sx={{ color: '#d32f2f' }}>{errors.contentMessage}</FormHelperText>
          ) : null}
        </FormControl>

        <LoadingButton
          onClick={save}
          disabled={loading}
          loading={loading}
          variant='contained'
          sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
          {state.id ? 'Modifica' : 'Adauga'}
        </LoadingButton>
      </Box>
    </Box>
  );
};
