import React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { Close } from '@mui/icons-material';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import ApiService from '../../actions/apiService';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
type FormState = {
  id: string | null;
  website: string;
  description: string;
  dimensions: {
    web: { width: string; height: string };
    mobile: { width: string; height: string };
  };
  defaultValue?: any;
};

type ContentType = 'web' | 'mobile';

export default (props: { isOpen: boolean; item?: any | null; close: (reloadList: boolean) => void }) => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  useEffect(() => {
    if (props.isOpen) {
      setState((prev) => ({
        ...prev,
        id: props.item?.id || null,
        website: props.item?.website?._id || '',
        description: props.item?.description || '',
        defaultValue: props?.item?.website
          ? { label: props.item.website.name, value: props.item.website._id }
          : { label: '', value: '' }
      }));
      setErrors((prev) => ({ ...prev, server: '', website: false, websiteMessage: '' }));
    }
  }, [props.isOpen]);

  const [list, setList] = useState({
    loading: true,
    rows: [],
    pageSize: 10,
    page: 1
  });

  useEffect(() => {
    setList((prev) => ({ ...prev, loading: true }));
    getItems();
  }, [list.page, list.pageSize]);

  const getItems = async () => {
    let json: IAPIResponse | null = null;
    try {
      const query: QueryParams = {};

      json = await ApiService.get(userContext.state.token, query, `website`);
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
    setList((prev) => ({
      ...prev,
      loading: false,
      rows: json ? json.docs.map((item: any) => ({ label: item.name, value: item._id })) : [],
      rowCount: json ? json.totalDocs : 0
    }));
  };
  const [state, setState] = useState<FormState>({
    id: null,
    website: '',
    description: '',
    dimensions: {
      web: {
        width: '',
        height: ''
      },
      mobile: {
        width: '',
        height: ''
      }
    },
    defaultValue: {}
  });

  const [errors, setErrors] = useState<{ server: string; website: boolean; websiteMessage: string }>({
    server: '',
    website: false,
    websiteMessage: ''
  });

  const [loading, setLoading] = useState(false);

  const save = async () => {
    setErrors((prev) => ({ ...prev, uniqueId: false, uniqueIdMessage: '', server: '' }));

    const req: any = {
      website: state.website,
      description: state.description,
      dimensions: state.dimensions
    };

    try {
      setLoading(true);

      if (!state.id) {
        //is new
        const response = await ApiService.post(userContext.state.token, req, 'location');
        props.close(true);
        showMessage(UIContext)('Successfully added!', 'success');
        return response;
      } else {
        //is edit
        const response = await ApiService.put(userContext.state.token, req, `location/${state.id}`);
        props.close(true);
        showMessage(UIContext)('Successfully changed!', 'success');
        return response;
      }
    } catch (err) {
      console.error(err);
      setErrors((prev) => ({ ...prev, server: err.message }));
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleDimensions = (e: React.ChangeEvent<HTMLInputElement>, content: ContentType) => {
    setState((prev) => ({
      ...prev,
      dimensions: {
        ...prev.dimensions,
        [content]: {
          ...prev.dimensions[content], // Preserve previous values
          [e.target.name]: e.target.value
        }
      }
    }));
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        props.close(false);
      }}>
      <Box
        sx={{
          width: { xs: '370px', sm: '460px', md: '500px', lg: '600px' },
          bgcolor: '#ffffff',
          boxShadow: '24px',
          padding: '32px',
          borderRadius: 4,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography component='h2' variant='h6' sx={{ color: '#222326' }}>
            {state.id ? 'Update location' : 'Create location'}
          </Typography>
          <IconButton onClick={() => props.close(false)}>
            <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
          </IconButton>
        </Box>
        <Box>{errors.server ? <Alert severity='error'>{errors.server}</Alert> : null}</Box>
        <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
          <Select
            options={list.rows}
            value={state.defaultValue}
            onChange={(val) => {
              setState((prev) => ({ ...prev, website: val.value, defaultValue: val }));
            }}
          />
          <TextField
            onChange={(e) => setState((prev) => ({ ...prev, description: e.target.value }))}
            variant='standard'
            required
            value={state.description}
            fullWidth
            label='Description'
            name='description'
          />
          <Typography>Dimensions - desktop</Typography>
          <Grid container xs={12}>
            <Grid item xs={5}>
              <TextField
                id='web-banner-width'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDimensions(e, 'web')}
                variant='standard'
                required
                value={state.dimensions.web.width}
                fullWidth
                label='Desktop banner width (px)'
                name='width'
                type='number'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                id='web-banner-height'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDimensions(e, 'web')}
                variant='standard'
                required
                value={state.dimensions.web.height}
                fullWidth
                label='Desktop banner height (px)'
                name='height'
                type='number'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Typography>Dimensions - mobile</Typography>
          <Grid container xs={12}>
            <Grid item xs={5}>
              <TextField
                id='mobile-banner-width'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDimensions(e, 'mobile')}
                variant='standard'
                required
                value={state.dimensions.mobile.width}
                fullWidth
                label='Mobile banner width (px)'
                name='width'
                type='number'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                id='mobile-banner-height'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDimensions(e, 'mobile')}
                variant='standard'
                required
                value={state.dimensions.mobile.height}
                fullWidth
                label='Mobile banner height (px)'
                name='height'
                type='number'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <LoadingButton
            onClick={save}
            disabled={loading}
            loading={loading}
            variant='contained'
            sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
            {state.id ? 'Modifica' : 'Adauga'}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
