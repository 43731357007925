import React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { Close } from '@mui/icons-material';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import { SelectChangeEvent } from '@mui/material/Select';
import InputField from '../components/InputField';
import SelectField from '../components/SelectField';
import ApiService from '../../actions/apiService';
import SelectMultiple from '../components/SelectMultiple';
import FunctionalIFrameComponent from './scenes/Iframe';
import config from '../../config';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';

const DOMPurify = require('dompurify');
interface FormData {
  id: string | null;
  banner: string;
  location: string;
  name: string;
  defaultValue?: any;
  selectedBanner: any;
  selectedLocation: any;
}

interface IError {
  server: string;
  banner: boolean;
  bannerMessage: string;
  name: boolean;
  nameMessage: string;
  location: boolean;
  locationMessage: string;
  content: boolean;
  contentMessage: string;
}

interface IList {
  loading: boolean;
  banners: any[];
  locations: any[];
  defaultLocations: any[];
  defaultBanners: any[];
  pageSize: number;
  page: number;
}

export default (props: { close: (reloadList: boolean) => void }) => {
  const userContext: any = useUser();
  const UIContext = useUI();
  const navigate = useNavigate();
  if (!userContext || !UIContext) return null;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      id: null,
      location: '',
      name: '',
      content: '',
      defaultValue: { label: '', value: '' },
      selectedBanner: {}
    }));
    setErrors((prev) => ({ ...prev, server: '', location: false, locationMessage: '' }));
  }, []);

  const [state, setState] = useState<FormData>({
    id: null,
    banner: '',
    location: '',
    name: '',
    defaultValue: '',
    selectedBanner: {},
    selectedLocation: {}
  });

  const [list, setList] = useState<IList>({
    loading: true,
    banners: [],
    locations: [],
    defaultLocations: [],
    defaultBanners: [],
    pageSize: 10,
    page: 1
  });

  const [errors, setErrors] = useState<IError>({
    server: '',
    banner: false,
    bannerMessage: '',
    name: false,
    nameMessage: '',
    location: false,
    locationMessage: '',
    content: false,
    contentMessage: ''
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setList((prev) => ({ ...prev, loading: true }));
    getItems();
  }, [list.page, list.pageSize, state.location]);

  const handleChangeBanner = async (event: SelectChangeEvent) => {
    setState((prv) => ({ ...prv, [event.target.name]: event.target.value }));
    const query: QueryParams = {};
    const banner = await ApiService.get(userContext.state.token, query, `banner/${event.target.value}`);
    if (banner) {
      setState((prev) => ({ ...prev, selectedBanner: banner }));
      const { dimensions } = banner;
      const { selectedLocation } = state;
      const { dimensions: locationDimensions } = selectedLocation;
      const { web, mobile } = locationDimensions;
      if (selectedLocation && web.width && web.height) {
        if (
          dimensions.height !== state.selectedLocation.height &&
          dimensions.width !== state.selectedLocation.width
        ) {
          const getRespose = confirm(
            'Warning: the selected banner does not comply with the recommended dimensions for the selected location. Do you want to continue?'
          );
          if (getRespose) {
            console.log('yes');
          } else {
            setState((prev) => ({ ...prev, banner: '', selectedBanner: {} }));
          }
        }
      }
    }
  };

  const handleChangeLocation = async (event: SelectChangeEvent) => {
    setState((prv) => ({ ...prv, [event.target.name]: event.target.value }));

    const selectedLocation = list.defaultLocations.find((loc: any) => loc._id === event.target.value);
    if (selectedLocation) {
      setState((prev) => ({ ...prev, selectedLocation }));
    }
  };

  const getItems = async () => {
    try {
      const query: QueryParams = {};

      const [locations, banners] = await Promise.all([
        ApiService.get(userContext.state.token, query, `location`),
        ApiService.get(userContext.state.token, query, `banner`)
      ]);

      setList((prev) => ({
        ...prev,
        loading: false,
        locations: locations
          ? locations.docs.map((item: any) => ({ label: item.description, value: item._id }))
          : [],
        defaultLocations: locations.docs,
        defaultBanners: banners.docs,
        banners: banners ? banners.docs.map((item: any) => ({ label: item.name, value: item._id })) : [],
        rowCount: locations ? locations.totalDocs : 0
      }));
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
  };

  const save = async () => {
    setErrors((prev) => ({ ...prev, uniqueId: false, uniqueIdMessage: '', server: '' }));
    //validation
    let isValid = true;
    if (!state.name) {
      setErrors((prev) => ({ ...prev, name: true, nameMessage: 'Name is required' }));
      isValid = false;
    }
    if (!state.location) {
      setErrors((prev) => ({ ...prev, location: true, locationMessage: 'Location is required' }));
      isValid = false;
    }

    if (state.banner.length === 0) {
      setErrors((prev) => ({ ...prev, banner: true, bannerMessage: 'Banner is required' }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const req: any = {
      location: state.location,
      banner: state.banner,
      website: list.defaultLocations.find((loc: any) => loc._id === state.location).website._id,
      name: state.name
    };

    try {
      setLoading(true);
      const response = await ApiService.post(userContext.state.token, req, 'adv');
      props.close(true);
      showMessage(UIContext)('Successfully added!', 'success');
      navigate(`/advertising/${response._id}`);
    } catch (err) {
      setErrors((prev) => ({ ...prev, server: err.message }));
      return null;
    } finally {
      setLoading(false);
    }
  };

  const sanitizedData = (htmldata: any) => ({
    __html: DOMPurify.sanitize(htmldata, { WHOLE_DOCUMENT: true })
  });

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '100%' }
      }}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography component='h2' variant='h6' sx={{ color: '#222326' }}>
          Create new ad placement
        </Typography>
        <IconButton onClick={() => props.close(false)}>
          <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
        </IconButton>
      </Box>
      <Box>{errors.server ? <Alert severity='error'>{errors.server}</Alert> : null}</Box>
      <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
        <InputField
          handleInputChange={(e: any) => setState((prev) => ({ ...prev, name: e.target.value }))}
          value={state.name}
          label={'Ad placement name'}
          name={'name'}
          error={errors.name}
          message={errors.nameMessage}
          handleErrorChange={() => setErrors((prev) => ({ ...prev, name: false, nameMessage: '' }))}
        />
        <SelectField
          value={state.location}
          name={'location'}
          handleChangeWebsite={handleChangeLocation}
          label='Location'
          list={list.locations}
          error={errors.location}
          message={errors.locationMessage}
          handleErrorChange={() => setErrors((prev) => ({ ...prev, location: false, locationMessage: '' }))}
        />
        <SelectField
          value={state.banner}
          name={'banner'}
          handleChangeWebsite={handleChangeBanner}
          label='Banner'
          list={list.banners}
          error={errors.banner}
          message={errors.bannerMessage}
          handleErrorChange={() => setErrors((prev) => ({ ...prev, banner: false, bannerMessage: '' }))}
        />

        <LoadingButton
          onClick={save}
          disabled={loading}
          loading={loading}
          variant='contained'
          sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
          Save ad placement
        </LoadingButton>
      </Box>

      <CardContent>
        <Typography variant='h6' gutterBottom component='div'>
          Selected banner preview - Desktop
        </Typography>
        <Box>
          <FunctionalIFrameComponent title='functional-iframe'>
            {state.selectedBanner?.image?.web && (
              <img
                width={'100%'}
                src={`${config.apiUrl}/api/banner/${state.selectedBanner?._id}/content?type=web`}
              />
            )}
            {state.selectedBanner?.content?.web && (
              <div dangerouslySetInnerHTML={sanitizedData(state.selectedBanner.content?.web)} />
            )}
          </FunctionalIFrameComponent>
        </Box>

        <Typography variant='h6' gutterBottom component='div'>
          Selected banner preview - Mobile
        </Typography>
        <Box>
          <FunctionalIFrameComponent title='functional-iframe'>
            {state.selectedBanner?.image?.mobile && (
              <img
                width={'100%'}
                src={`${config.apiUrl}/api/banner/${state.selectedBanner?._id}/content?type=mobile`}
              />
            )}
            {state.selectedBanner?.content?.mobile && (
              <div dangerouslySetInnerHTML={sanitizedData(state.selectedBanner.content?.mobile)} />
            )}
          </FunctionalIFrameComponent>
        </Box>
      </CardContent>
    </Box>
  );
};
