import { useUser } from '../../../context/user';
import { useUI } from '../../../context/ui';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ApiService from '../../../actions/apiService';
import { showMessage } from '../../../actions/ui';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Title from '../../components/Title';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import InputField from '../../components/InputField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { UrlInputArrayComponent } from '../components/UrlInputArray';

const isValidUrl = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
};

interface IError {
  server: string;
  name: boolean;
  nameMessage: string;
  url: boolean;
  urlMessage: string;
}

export const UpdateWebsite = () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  const params = useParams();
  const navigate = useNavigate();
  const [website, setWebsite] = useState<{
    loading: boolean;
    name: string;
    url: string;
    excludedUrlArray: string[];
    allowList: string[];
  }>({
    name: '',
    url: '',
    loading: true,
    excludedUrlArray: [],
    allowList: []
  });

  const [errors, setErrors] = useState<IError>({
    server: '',
    name: false,
    nameMessage: '',
    url: false,
    urlMessage: ''
  });

  const getWebsiteDetails = async () => {
    try {
      const query: QueryParams = {};

      const response = await ApiService.get(userContext.state.token, query, `website/${params.id}`);

      setWebsite((prev: any) => ({
        ...prev,
        loading: false,
        name: response.name,
        url: response.url,
        excludedUrlArray: response.excludedUrl ?? [],
        allowList: response.allowList
      }));
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
  };

  useEffect(() => {
    getWebsiteDetails();
  }, []);

  const save = async () => {
    setErrors((prev) => ({ ...prev, uniqueId: false, uniqueIdMessage: '', server: '' }));

    let isValid = true;
    if (!website.name) {
      setErrors((prev) => ({ ...prev, name: true, nameMessage: 'Name is required' }));
      isValid = false;
    }
    if (!website.url) {
      setErrors((prev) => ({ ...prev, url: true, urlMessage: 'Website is required' }));
      isValid = false;
    }

    if (!isValidUrl(website.url)) {
      setErrors((prev) => ({ ...prev, url: true, urlMessage: 'Invalid url' }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const req: any = {
      name: website.name,
      url: website.url,
      excludedUrl: website.excludedUrlArray,
      allowList: website.allowList
    };

    try {
      const response = await ApiService.patch(userContext.state.token, req, `website/${params.id}`);
      if (response) {
        showMessage(UIContext)('Successfully updated!', 'success');
      }

      return response;
    } catch (err) {
      return null;
    }
  };

  const deleteWebsite = async (websiteId: string) => {
    const isExecuted = confirm('Are you sure to execute this action?');
    let json: IAPIResponse | null = null;
    if (isExecuted) {
      json = await ApiService.deleteService(userContext.state.token, `website/${websiteId}`);
      if (json) {
        navigate('/website');
      }
    }
    return null;
  };

  return (
    <Card sx={{ maxWidth: '100%' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
            W
          </Avatar>
        }
        title={website.name || ''}
        action={
          <IconButton onClick={() => navigate('/website')}>
            <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
          </IconButton>
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Title> Website details</Title>
            <Divider />

            <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
              <InputField
                handleInputChange={(e: any) =>
                  setWebsite((prev: any) => ({
                    ...prev,
                    name: e.target.value
                  }))
                }
                value={website.name}
                label='Website name'
                name='name'
                error={errors.name}
                message={errors.nameMessage}
                handleErrorChange={() =>
                  setErrors((prev: any) => ({ ...prev, name: false, nameMessage: '' }))
                }
              />
              <InputField
                handleInputChange={(e: any) =>
                  setWebsite((prev: any) => ({
                    ...prev,
                    url: e.target.value
                  }))
                }
                value={website.url}
                label='Website url'
                name='url'
                error={errors.url}
                message={errors.urlMessage}
                handleErrorChange={() => setErrors((prev: any) => ({ ...prev, url: false, urlMessage: '' }))}
              />

              <UrlInputArrayComponent
                name='AllowList'
                label='Enter allowed URL'
                helperText={`This url should match the exact url where the banner will be displayed or an "*"`}
                urlArray={website.allowList}
                setExcludedUrls={(urls: string[]) => {
                  setWebsite((prevData) => ({ ...prevData, allowList: urls }));
                }}
              />

              <UrlInputArrayComponent
                name='ExcludeList'
                label='Enter excluded URL'
                helperText='This url should match the exact url where the banner should not be displayed'
                urlArray={website.excludedUrlArray}
                setExcludedUrls={(urls: string[]) => {
                  setWebsite((prevData) => ({ ...prevData, excludedUrlArray: urls }));
                }}
              />
            </Box>

            <Divider />
            <FormControl>
              <Grid container justifyContent='end' columnGap='5px'>
                <Grid item>
                  <Button
                    sx={{ mt: '8px' }}
                    color={'error'}
                    variant='outlined'
                    startIcon={<DeleteIcon />}
                    onClick={() => deleteWebsite(params.id ?? '')}>
                    Delete
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    onClick={save}
                    disabled={website.loading}
                    loading={website.loading}
                    variant='outlined'
                    sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
                    <SaveIcon /> Save changes
                  </LoadingButton>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
