import React from 'react';
import { useState, useEffect } from 'react';
import { useUI } from '../../../context/ui';
import { useUser } from '../../../context/user';
import { showMessage } from '../../../actions/ui';
import ApiService from '../../../actions/apiService';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
export default function TopBanners() {
  const userContext: any = useUser();
  const UIContext = useUI();
  const navigate = useNavigate();
  if (!userContext || !UIContext) return null;

  const [list, setList] = useState<IGridState>({
    loading: true,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10],
    pageSize: 10,
    page: 1,
    rowCount: 0,
    sort: 'nrViews',
    asc: false,
    search: ''
  });

  const getItems = async () => {
    const { search, asc, sort, pageSize, page } = list;

    let json: IAPIResponse | null = null;
    try {
      const query: QueryParams = {
        page: page
      };

      if (pageSize) {
        query.pageSize = pageSize;
      }

      if (sort) {
        query.sort = sort;
        // @ts-ignore
        query.asc = asc.toString();
      }

      if (search) {
        query.search = search;
      }

      json = await ApiService.get(userContext.state.token, query, 'website/top');
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }

    setList((prev) => ({
      ...prev,
      loading: false,
      rows: json
        ? json.docs
            .filter((item: any) => item.website)
            .map((item: any) => {
              return {
                id: item?.website._id,
                'website.name': item.website.name,
                nrViews: item.nrViews,
                nrClicks: item.nrClicks
              };
            })
        : [],
      rowCount: json ? json.totalDocs : 0
    }));
  };

  useEffect(() => {
    setList((prev) => ({ ...prev, loading: true }));
    getItems();
  }, [list.page, list.pageSize, list.sort, list.asc, list.search]);

  return (
    <>
      <DataGrid
        disableSelectionOnClick={true}
        autoHeight={true}
        pagination={true}
        loading={list.loading}
        rowCount={list.rowCount}
        rowsPerPageOptions={list.rowsPerPageOptions}
        rows={list.rows}
        columns={[
          { field: 'id', headerName: 'ID', width: 220 },
          { field: 'website.name', headerName: 'Website name', width: 500, sortable: false },
          { field: 'nrViews', headerName: 'Views', width: 200 },
          { field: 'nrClicks', headerName: 'Clicks', width: 160 }
        ]}
        showCellRightBorder
        paginationMode='server'
        pageSize={list.pageSize}
        onPageChange={(d: number) => {
          setList((prev) => ({ ...prev, page: d + 1 }));
        }}
        onPageSizeChange={(d: number) => {
          setList((prev) => ({ ...prev, page: 1, pageSize: d }));
        }}
        sortingOrder={['asc', 'desc']}
        onSortModelChange={(d: any) => {
          if (d && d.length > 0) {
            setList((prev) => ({ ...prev, sort: d[0].field, asc: d[0].sort === 'asc' }));
          }
        }}
        components={{
          Footer: () => (
            <Button
              variant='text'
              onClick={() => navigate('/website')}
              sx={{ float: 'right', mt: '8px', minWidth: '80px' }}>
              View all
            </Button>
          )
        }}
      />
    </>
  );
}
