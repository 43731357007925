import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import TopBanners from './scenes/topBanners';
import TopWebsites from './scenes/topWebsites';
import Title from '../components/Title';
export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>Top Ads</Title>
            <TopBanners />
          </Grid>
          <Grid item xs={12}>
            <Title>Top websites</Title>
            <TopWebsites />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
