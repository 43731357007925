import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';

import theme from './theme';

import NotFound from './pages/404';
import Login from './pages/login';

import { UserProvider } from './context/user';
import { UIProvider, useUI } from './context/ui';
import { hideMessage } from './actions/ui';

import Alert from '@mui/material/Alert';

import PrivateRoute from './components/routes/private';

import Layout from './layout';
import Dashboard from './pages/dashboard';
import Banner from './pages/home/index';
import Website from './pages/website';
import Location from './pages/location/index';
import LocationDetails from './pages/location/scenes/index';
import BannerDetails from './pages/home/scenes/index';
import WebsiteDetails from './pages/website/scenes/index';
import Advertising from './pages/advertising';
import AdvertisingDetails from './pages/advertising/scenes/index';
import Client from './pages/client/index';
import ClientDetails from './pages/client/scenes/index';
import { UpdateWebsite } from './pages/website/scenes/UpdateWebsite';
const GeneralSnackbar = () => {
  const UIContext = useUI();
  if (!UIContext) return null;

  return (
    <Snackbar
      open={UIContext.state.isSnackbarOpen}
      autoHideDuration={4000}
      // message={UIContext.state.message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={hideMessage(UIContext)}
      action={
        <IconButton size='small' aria-label='close' color='inherit' onClick={hideMessage(UIContext)}>
          <Close fontSize='small' />
        </IconButton>
      }>
      <Alert onClose={hideMessage(UIContext)} severity={UIContext.state.severity} sx={{ width: '100%' }}>
        {UIContext.state.message}
      </Alert>
    </Snackbar>
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <UserProvider>
      <UIProvider>
        <GeneralSnackbar />
        <BrowserRouter>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route
              path='/*'
              element={
                <PrivateRoute>
                  <Layout>
                    <Routes>
                      <Route path='/' element={<Dashboard />} />
                      <Route path='/banner' element={<Banner />} />
                      <Route path={'/client'} element={<Client />} />
                      <Route path={'/client/:id'} element={<ClientDetails />} />
                      <Route path='/banner/:id' element={<BannerDetails />} />
                      <Route path='/website' element={<Website />} />
                      <Route path='/website/:id' element={<WebsiteDetails />} />
                      <Route path='/website/:id/update' element={<UpdateWebsite />} />
                      <Route path='/location' element={<Location />} />
                      <Route path='/location/:id' element={<LocationDetails />} />
                      <Route path='/advertising' element={<Advertising />} />
                      <Route path='/advertising/:id' element={<AdvertisingDetails />} />
                      <Route path='*' element={<NotFound />} />
                    </Routes>
                  </Layout>
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </UIProvider>
    </UserProvider>
  </ThemeProvider>,
  document.querySelector('#root')
);
