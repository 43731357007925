import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import Search from '../components/search';
import Button from '@mui/material/Button';
import CreateBanner from './createBanner';
import ApiService from '../../actions/apiService';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import Title from '../components/Title';
export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;

  const [list, setList] = useState<IGridState>({
    loading: true,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10],
    pageSize: 10,
    page: 1,
    rowCount: 0,
    asc: true,
    search: '',
    sort: 'name',
    filters: {}
  });

  const getItems = async () => {
    const { search, pageSize, page, asc, sort, filters } = list;

    let json: IAPIResponse | null = null;
    try {
      const query: QueryParams = {
        page: page
      };

      if (pageSize) {
        query.pageSize = pageSize;
      }

      if (search) {
        query.search = search;
      }
      if (sort) {
        query.sort = sort;
        // @ts-ignore
        query.asc = asc.toString();
      }
      if (filters) {
        query.filters = filters;
      }

      json = await ApiService.get(userContext.state.token, query, 'banner');
    } catch (err) {
      console.error(err);
      showMessage(UIContext)('An error occured!', 'error');
    }

    const { docs, meta } = json || {};

    setList((prev) => ({
      ...prev,
      loading: false,
      rows: json
        ? docs.map((item: any) => {
            return {
              id: item._id,
              name: item.name,
              client: item.client,
              nrViews: item.nrViews,
              nrClicks: item.nrClicks
            };
          })
        : [],
      rowCount: meta ? meta.total : 0,
      totalPages: meta ? meta.pages : 1,
      page: meta ? meta.page : list.page
    }));
  };

  useEffect(() => {
    setList((prev) => ({ ...prev, loading: true }));
    getItems();
  }, [list.page, list.pageSize, list.search, list.asc, list.sort, list.filters]);

  const [modal, setModal] = useState<{ open: boolean }>({
    open: false
  });

  const removeBanner = async (bannerId: string) => {
    const isExecuted = confirm('Are you sure to execute this action?');
    let json: IAPIResponse | null = null;
    if (isExecuted) {
      json = await ApiService.deleteService(userContext.state.token, `banner/${bannerId}`);
      if (json) {
        getItems();
      }
    }
    return null;
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        {!modal.open && (
          <>
            <Search
              search={(value) => {
                setList((prev) => ({ ...prev, search: value }));
              }}
              filter={(filters) => {
                setList((prev) => ({ ...prev, filters }));
              }}
            />
            <Title>Banners</Title>
            <DataGrid
              disableSelectionOnClick={true}
              autoHeight={true}
              pagination={true}
              loading={list.loading}
              rowCount={list.rowCount}
              rowsPerPageOptions={list.rowsPerPageOptions}
              rows={list.rows}
              columns={[
                { field: 'id', headerName: 'ID', width: 220 },
                { field: 'name', headerName: 'Banner name', width: 250, sortable: false },
                { field: 'client', headerName: 'Ad client', width: 150, sortable: false },
                { field: 'nrViews', headerName: 'Views', width: 150 },
                { field: 'nrClicks', headerName: 'Clicks', width: 150 },
                {
                  field: 'edit',
                  sortable: false,
                  headerName: 'Edit / Delete',
                  type: 'actions',
                  width: 150,
                  renderCell: (params: any) => {
                    return (
                      <>
                        <Link to={'/banner/' + params.row.id}>
                          <IconButton aria-label='delete'>
                            <EditIcon />
                          </IconButton>
                        </Link>

                        <IconButton
                          aria-label='delete'
                          color={'error'}
                          onClick={() => removeBanner(params.row.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    );
                  }
                }
              ]}
              showCellRightBorder
              paginationMode='server'
              pageSize={list.pageSize}
              onPageChange={(d: number) => {
                setList((prev) => ({ ...prev, page: d + 1 }));
              }}
              onPageSizeChange={(d: number) => {
                setList((prev) => ({ ...prev, page: 1, pageSize: d }));
              }}
              sortingMode='server'
              sortingOrder={['asc', 'desc']}
              onSortModelChange={(d: any) => {
                if (d && d.length > 0) {
                  setList((prev) => ({ ...prev, sort: d[0].field, asc: d[0].sort === 'asc' }));
                }
              }}
            />
          </>
        )}
        {!modal.open && (
          <Button
            variant='contained'
            onClick={() => setModal((prev) => ({ ...prev, open: true }))}
            sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
            <AddIcon /> New Banner
          </Button>
        )}

        {modal.open && (
          <CreateBanner
            close={(reloadList) => {
              setModal((prev) => ({ ...prev, open: false }));
              if (reloadList) {
                getItems();
              }
            }}
          />
        )}
      </Paper>
    </Grid>
  );
};
