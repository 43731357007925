import React, { useState } from 'react';
import { createPortal } from 'react-dom';

export default function FunctionalIFrameComponent(props: { title: string; children: any }) {
  const [contentRef, setContentRef] = useState<any>(null);
  // @ts-ignore
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe style={{ height: '100%', width: '100%', minHeight: '400px' }} {...props} ref={setContentRef}>
      {mountNode && createPortal(props.children, mountNode)}
    </iframe>
  );
}
