import React from 'react';
import { useState, useEffect } from 'react';
import { useUI } from '../../../context/ui';
import { useUser } from '../../../context/user';
import { showMessage } from '../../../actions/ui';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import ApiService from '../../../actions/apiService';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { SelectChangeEvent } from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import Title from '../../components/Title';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
interface IError {
  server: string;
  name: boolean;
  nameMessage: string;
  website: boolean;
  websiteMessage: string;
  location: boolean;
  locationMessage: string;
  content: boolean;
  contentMessage: string;
}

type ContentType = 'web' | 'mobile';

export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  const params = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState<{
    loading: boolean;
    details: any;
    website: string;
    websiteList: any[];
  }>({
    website: '',
    websiteList: [],
    loading: true,
    details: {
      description: '',
      status: false,
      website: [],
      dimensions: {
        web: {
          width: '',
          height: ''
        },
        mobile: {
          width: '',
          height: ''
        }
      },
      bannersDetails: [],
      bannersStatuses: [],
      _id: ''
    }
  });

  const [errors, setErrors] = useState<IError>({
    server: '',
    name: false,
    nameMessage: '',
    website: false,
    websiteMessage: '',
    location: false,
    locationMessage: '',
    content: false,
    contentMessage: ''
  });

  const getItems = async () => {
    try {
      const query: QueryParams = {};

      const [json, websites] = await Promise.all([
        ApiService.get(userContext.state.token, query, `location/${params.id}`),
        ApiService.get(userContext.state.token, query, `website`)
      ]);

      setLocation((prev) => ({
        ...prev,
        loading: false,
        websiteList: websites
          ? websites.docs.map((item: any) => ({ label: item.name, value: item._id }))
          : [],
        website: json ? json.website._id : '',
        details: {
          ...prev.details,
          description: json ? json.description : '',
          status: json.status,
          website: json ? json.website : '',
          dimensions: json.dimensions ? json.dimensions : prev.details.dimensions,
          bannersDetails: json ? json.bannersDetails : [],
          details: json ? json.details : [],
          bannersStatuses: json ? json.banners : [],
          _id: json ? json._id : ''
        }
      }));
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const save = async () => {
    setErrors((prev) => ({ ...prev, uniqueId: false, uniqueIdMessage: '', server: '' }));
    //validation
    let isValid = true;
    if (!location.details.description) {
      setErrors((prev) => ({ ...prev, name: true, nameMessage: 'Name is required' }));
      isValid = false;
    }
    if (!location.website) {
      setErrors((prev) => ({ ...prev, website: true, websiteMessage: 'Website is required' }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const req: any = {
      website: location.website,
      description: location.details.description,
      status: location.details.status.toString(),
      dimensions: location.details.dimensions
    };

    try {
      const response = await ApiService.put(userContext.state.token, req, `location/${params.id}`);
      if (response) {
        showMessage(UIContext)('Successfully updated!', 'success');
        getItems();
      }

      return response;
    } catch (err) {
      return null;
    }
  };

  const deleteLocation = async (locationId: string) => {
    const isExecuted = confirm('Are you sure to execute this action?');
    let json: IAPIResponse | null = null;
    if (isExecuted) {
      json = await ApiService.deleteService(userContext.state.token, `location/${locationId}`);
      if (json) {
        getItems().catch((err) => console.log(err));
      }
    }
    return null;
  };

  const handleChangeWebsite = (event: SelectChangeEvent) => {
    setLocation((prv) => ({ ...prv, [event.target.name]: event.target.value }));
  };

  const handleDimensions = (e: React.ChangeEvent<HTMLInputElement>, content: ContentType) => {
    setLocation((prev) => ({
      ...prev,
      details: {
        ...prev.details,
        dimensions: {
          ...prev.details.dimensions,
          [content]: {
            ...prev.details.dimensions[content], // Preserve previous values
            [e.target.name]: e.target.value
          }
        }
      }
    }));
  };

  return (
    <Card sx={{ maxWidth: '100%' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
            L
          </Avatar>
        }
        title={location?.details?.description || ''}
        subheader={`Location Id: ${location.details._id}`}
        action={
          <IconButton onClick={() => navigate('/location')}>
            <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
          </IconButton>
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Title> Location details</Title>
            <Divider />

            <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
              <InputField
                handleInputChange={(e: any) =>
                  setLocation((prev) => ({
                    ...prev,
                    details: { ...prev.details, description: e.target.value }
                  }))
                }
                value={location.details.description}
                label={'Location name'}
                name={'description'}
                error={errors.location}
                message={errors.locationMessage}
                handleErrorChange={() =>
                  setErrors((prev) => ({ ...prev, location: false, locationMessage: '' }))
                }
              />

              <SelectField
                value={location.website}
                name={'website'}
                handleChangeWebsite={handleChangeWebsite}
                label='Website'
                list={location.websiteList}
                error={errors.website}
                message={errors.websiteMessage}
                handleErrorChange={() =>
                  setErrors((prev) => ({ ...prev, website: false, websiteMessage: '' }))
                }
              />
              <Typography>Dimensions - desktop</Typography>
              <Grid container xs={12} style={{ marginBottom: '1rem' }}>
                <Grid item xs={5}>
                  <TextField
                    onChange={(e: any) => handleDimensions(e, 'web')}
                    variant='standard'
                    required
                    value={location.details.dimensions.web.width || ''}
                    fullWidth
                    label='Banner width (px)'
                    name='width'
                    type='number'
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  <TextField
                    onChange={(e: any) => handleDimensions(e, 'web')}
                    variant='standard'
                    required
                    value={location.details.dimensions.web.height || ''}
                    fullWidth
                    label='Banner height (px)'
                    name='height'
                    type='number'
                  />
                </Grid>
              </Grid>
              <Typography>Dimensions - mobile</Typography>
              <Grid container xs={12} style={{ marginBottom: '1rem' }}>
                <Grid item xs={5}>
                  <TextField
                    onChange={(e: any) => handleDimensions(e, 'mobile')}
                    variant='standard'
                    required
                    value={location.details.dimensions.mobile.width || ''}
                    fullWidth
                    label='Banner width (px)'
                    name='width'
                    type='number'
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  <TextField
                    onChange={(e: any) => handleDimensions(e, 'mobile')}
                    variant='standard'
                    required
                    value={location.details.dimensions.mobile.height || ''}
                    fullWidth
                    label='Banner height (px)'
                    name='height'
                    type='number'
                  />
                </Grid>
              </Grid>

              <TextField
                id='date'
                label='Deployment date'
                type='date'
                defaultValue={moment(location.details.editedAt).format('YYYY-MM-DD')}
                sx={{ width: 220, marginBottom: '20px' }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>

            <Divider />
            <FormControl>
              <Grid container justifyContent='end' columnGap='5px'>
                <Grid item>
                  <Button
                    sx={{ mt: '8px' }}
                    color={'error'}
                    variant='outlined'
                    startIcon={<DeleteIcon />}
                    onClick={() => deleteLocation(location.details._id)}>
                    Delete
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    onClick={save}
                    disabled={location.loading}
                    loading={location.loading}
                    variant='outlined'
                    sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
                    <SaveIcon /> Save changes
                  </LoadingButton>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <Title>Associated banners</Title>
            <Divider />
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Views</TableCell>
                    <TableCell>Clicks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(location?.details?.bannersDetails).map((row: any) => {
                    const bannerDetails = location.details.details.find(
                      (item: any) => item.banner === row._id
                    );

                    return (
                      <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{bannerDetails ? bannerDetails.nrViews : 0}</TableCell>
                        <TableCell>{bannerDetails ? bannerDetails.nrClicks : 0}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
