import React from 'react';
import { useState, useEffect } from 'react';
import { useUI } from '../../../context/ui';
import { useUser } from '../../../context/user';
import { showMessage } from '../../../actions/ui';
import config from '../../../config';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import ApiService from '../../../actions/apiService';
import FunctionalIFrameComponent from './Iframe';
import UpdateAdvertising from '../updateAdvertising';
const DOMPurify = require('dompurify');
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Close } from '@mui/icons-material';
import { Divider, TextareaAutosize } from '@mui/material';
import Title from '../../components/Title';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { StyleEditor } from './StyleEditor';

interface IAdvertising {
  id: string;
  loading: boolean;
  name: string;
  banner: any;
  locations: any[];
  website: any;
}

export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  const params = useParams();
  const [styles, setStyles] = useState<any>({
    'margin-top': '0px',
    'margin-bottom': '0px',
    'margin-left': '0px',
    'margin-right': '0px'
  });

  const [existingEmbedCode, setExistingEmbedCode] = useState<string>('');
  const [copied, setCopied] = useState(false);
  const [advertising, setAdvertising] = useState<IAdvertising>({
    id: '',
    loading: true,
    name: '',
    banner: {},
    website: {},
    locations: []
  });

  const [isOnUpdate, setIsOnUpdate] = useState<boolean>(false);

  const [details, setDetails] = useState<{ locationsDetails: any[]; websitesDetails: any[] }>({
    locationsDetails: [],
    websitesDetails: []
  });

  const sanitizedData = (data: any) => ({
    __html: DOMPurify.sanitize(data, { WHOLE_DOCUMENT: true })
  });

  const getItems = async () => {
    try {
      const query: QueryParams = {};
      const json = await ApiService.get(userContext.state.token, query, `adv/${params.id}`);
      setAdvertising((prev) => ({
        ...prev,
        id: json._id,
        name: json ? json.name : prev.name,
        banner: json ? json.banner : prev.banner,
        website: json ? json.website : prev.website,
        locations: json ? json.locations : prev.locations,
        loading: false
      }));

      setStyles(json.margin ? json.margin : styles);
      setDetails((prev) => ({
        ...prev,
        locationsDetails: json ? json.locationsDetails : [],
        websitesDetails: json ? json.websitesDetails : []
      }));
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
  };

  useEffect(() => {
    getItems().catch((err) => console.log(err));
  }, []);

  const changeBannerStatus = async (location: { _id: string }, locStatus: any) => {
    const req: any = {
      status: 'un_published'
    };

    let message: string = 'Are you sure you want to publish this banner?';
    if (!locStatus) {
      req.status = 'published';
    } else {
      locStatus.status === 'published' ? (req.status = 'un_published') : (req.status = 'published');
      message =
        locStatus.status === 'un_published'
          ? 'Are you sure you want to publish this banner?'
          : 'Are you sure you want to unpublish this banner';
    }

    const isExecuted = confirm(message);

    if (isExecuted) {
      await ApiService.put(
        userContext.state.token,
        req,
        `location/${location._id}/advertising/${advertising.id}`
      );
      getItems().catch((err) => console.log(err));
    }
  };

  const closeButton = (
    <Tooltip title='Close ad placement '>
      <IconButton onClick={() => setIsOnUpdate(!isOnUpdate)}>
        <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
      </IconButton>
    </Tooltip>
  );

  const moreButton = (
    <Tooltip title='Update ad placement'>
      <IconButton aria-label='settings' onClick={() => setIsOnUpdate(!isOnUpdate)}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );

  const locationToPublish = advertising.locations.map((loctionDetails: any) => {
    return {
      id: loctionDetails._id,
      advertising: loctionDetails.advertising,
      website: loctionDetails.website
    };
  });

  const getInitials = (name: string) => {
    if (!name) return '';
    const names = name.split(' ');
    const initials = names.map((name) => name.charAt(0).toUpperCase());
    return initials.join('').substring(0, 2);
  };

  const saveStyles = async () => {
    const margin = styles;
    const request = {
      margin
    };
    const response = await ApiService.patch(userContext.state.token, request, `adv/${advertising.id}/script`);

    if (response) showMessage(UIContext)('Saved!', 'success');
  };

  const styleString: string = Object.entries(styles)
    .map(([property, value]) => `${property}: ${value};`)
    .join(' ');

  return (
    <>
      {advertising.loading && <h1>Loading...</h1>}
      {!advertising.loading && (
        <Card sx={{ maxWidth: '100%' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
                {getInitials(advertising.name)}
              </Avatar>
            }
            action={isOnUpdate ? closeButton : moreButton}
            title={advertising.name || ''}
            subheader={`Advertising Id: ${params.id}`}
          />

          {!isOnUpdate && (
            <>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Title>Websites and locations details</Title>

                    <TableContainer component={Paper}>
                      <Divider />
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Location name</TableCell>
                            <TableCell>Website</TableCell>
                            <TableCell>Published</TableCell>
                            <TableCell align='right'>Views</TableCell>
                            <TableCell align='right'>Clicks</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {advertising.locations.map((location: any) => {
                            const locStatus = location.advertising.find(
                              (item: any) => item.advertisingId === advertising.id
                            );
                            const locDetails = details.locationsDetails.filter(
                              (loc: any) => loc.location === location._id
                            );
                            return (
                              <TableRow key={location._id}>
                                <TableCell component='th' scope='row'>
                                  <Link to={'/location/' + location._id}>{location.description}</Link>
                                </TableCell>
                                <TableCell>{advertising.website.name}</TableCell>
                                <TableCell>
                                  <IconButton
                                    aria-label='publish'
                                    onClick={() => changeBannerStatus(location, locStatus)}>
                                    {locStatus && locStatus.status === 'published' ? (
                                      <Tooltip title='Published'>
                                        <VisibilityIcon color='success' />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title='Unpublished'>
                                        <VisibilityOffIcon color='error' />
                                      </Tooltip>
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell align='right'>
                                  {locDetails.reduce((acc: number, val: any) => acc + val.nrViews, 0)}
                                </TableCell>
                                <TableCell align='right'>
                                  {locDetails.reduce((acc: number, val: any) => acc + val.nrClicks, 0)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={6}>
                    {locationToPublish.map((loc: any) => {
                      return (
                        <>
                          <Title>Change style</Title>
                          <StyleEditor styles={styles} setStyles={setStyles} />

                          <Button
                            variant='contained'
                            onClick={() => saveStyles()}
                            size='small'
                            style={{ marginTop: '1rem' }}>
                            Save styles
                          </Button>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {locationToPublish.map((loc: any) => {
                    const sourceCode = `<!-- Begin - ITH Ad Placement -->
<div id="${loc.id}" style="position: relative; height: auto; width: 100%; display: flex; justify-content: center; z-index: 2147483647; ${styleString}"></div>
<script src="${config.apiUrl}/api/adv/script?advertisingId=${params.id}&locationId=${loc.id}"></script>
<!-- End - ITH Ad Placement -->`;
                    return (
                      <>
                        <Title>Embed Code</Title>
                        <SyntaxHighlighter language='html' showLineNumbers={true} wrapLines={true}>
                          {sourceCode}
                        </SyntaxHighlighter>
                        <CopyToClipboard text={sourceCode} onCopy={() => setCopied(true)}>
                          <Button variant='outlined' size='small'>
                            {copied ? 'Copied!' : 'Copy'}
                          </Button>
                        </CopyToClipboard>
                      </>
                    );
                  })}
                </Grid>
              </CardContent>
              <CardContent>
                <Typography variant='h6' gutterBottom component='div'>
                  Selected banner preview - desktop
                </Typography>
                <Box>
                  <FunctionalIFrameComponent title='functional-iframe'>
                    {advertising.banner?.image?.web && (
                      <img
                        width={'100%'}
                        src={`${config.apiUrl}/api/banner/${advertising.banner?._id}/content?type=web`}
                      />
                    )}
                    {advertising.banner?.content?.web && (
                      <div dangerouslySetInnerHTML={sanitizedData(advertising.banner.content.web)} />
                    )}
                  </FunctionalIFrameComponent>
                </Box>

                <Typography variant='h6' gutterBottom component='div'>
                  Selected banner preview - mobile
                </Typography>
                <Box>
                  <FunctionalIFrameComponent title='functional-iframe'>
                    {advertising.banner?.image?.mobile && (
                      <img
                        width={'100%'}
                        src={`${config.apiUrl}/api/banner/${advertising.banner?._id}/content?type=mobile`}
                      />
                    )}
                    {advertising.banner?.content?.mobile && (
                      <div dangerouslySetInnerHTML={sanitizedData(advertising.banner.content.mobile)} />
                    )}
                  </FunctionalIFrameComponent>
                </Box>
              </CardContent>
            </>
          )}
          {isOnUpdate && (
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <UpdateAdvertising
                data={advertising}
                close={(isChanged = false) => {
                  setIsOnUpdate(!isOnUpdate);
                  if (isChanged) {
                    getItems().catch((err) => console.log(err));
                  }
                }}
              />
            </Paper>
          )}
        </Card>
      )}
    </>
  );
};
