import React, { useState, ChangeEvent } from 'react';
import { TextField, Grid } from '@mui/material';

interface StyleEditorProps {
  styles: any;
  setStyles: any;
}

export const StyleEditor: React.FC<StyleEditorProps> = (props: StyleEditorProps) => {
  const { setStyles, styles } = props;

  const handleMarginChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStyles((prevStyles: any) => ({
      ...prevStyles,
      [name]: value
    }));
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label='Margin Top'
            variant='outlined'
            name='margin-top'
            value={styles['margin-top']}
            onChange={handleMarginChange}
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Margin Bottom'
            variant='outlined'
            name='margin-bottom'
            value={styles['margin-bottom']}
            onChange={handleMarginChange}
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Margin Left'
            variant='outlined'
            name='margin-left'
            value={styles['margin-left']}
            onChange={handleMarginChange}
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Margin Right'
            variant='outlined'
            name='margin-right'
            value={styles['margin-right']}
            onChange={handleMarginChange}
            fullWidth
            size='small'
          />
        </Grid>
      </Grid>
    </div>
  );
};
