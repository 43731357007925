import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import Search from './search';
import Button from '@mui/material/Button';
import CreateBanner from './createAdvertising';
import ApiService from '../../actions/apiService';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Title from '../components/Title';
export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  const [list, setList] = useState<IGridState>({
    loading: true,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [10],
    totalPages: 1,
    pageSize: 10,
    page: 1,
    rowCount: 0,
    sort: 'createdAt',
    asc: false,
    search: '',
    filters: {}
  });

  const getItems = async () => {
    const { search, pageSize, page, sort, asc, filters } = list;

    let json: IAPIResponse | null = null;
    try {
      const query: QueryParams = {
        page: page
      };

      if (pageSize) {
        query.pageSize = pageSize;
      }

      if (search) {
        query.search = search;
      }
      if (sort) {
        query.sort = sort;
        // @ts-ignore
        query.asc = asc.toString();
      }

      if (filters) {
        query.filters = filters;
      }
      console.log({ filters });

      json = await ApiService.get(userContext.state.token, query, 'adv');
    } catch (err) {
      console.error(err);
      showMessage(UIContext)('An error occured!', 'error');
    }

    const { docs, meta } = json || {};

    setList((prev) => ({
      ...prev,
      loading: false,
      rows: json
        ? docs.map((item: any) => {
            return {
              id: item._id,
              name: item?.name,
              banner: item?.banner?.name,
              websites: item.websites,
              nrViews: item.nrViews,
              nrClicks: item.nrClicks
            };
          })
        : [],
      rowCount: meta ? meta.total : 0,
      totalPages: meta ? meta.pages : 1,
      page: meta ? meta.page : list.page
    }));
  };

  useEffect(() => {
    setList((prev) => ({ ...prev, loading: true }));
    getItems();
  }, [list.page, list.pageSize, list.search, list.asc, list.sort, list.filters]);

  const [modal, setModal] = useState<{ open: boolean }>({
    open: false
  });

  const deleteAdvertising = async (advertisingId: string) => {
    const isExecuted = confirm('Are you sure to execute this action?');
    let json: IAPIResponse | null = null;
    if (isExecuted) {
      json = await ApiService.deleteService(userContext.state.token, `adv/${advertisingId}`);
      if (json) {
        getItems().catch((err) => console.log(err));
      }
    }
    return null;
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        {!modal.open && (
          <>
            <Search
              search={(value) => {
                setList((prev) => ({ ...prev, search: value }));
              }}
              filter={(filters) => {
                setList((prev) => ({ ...prev, filters }));
              }}
            />

            <Title>Ad placement</Title>
            <DataGrid
              disableSelectionOnClick={true}
              autoHeight={true}
              pagination={true}
              loading={list.loading}
              rowCount={list.rowCount}
              rowsPerPageOptions={list.rowsPerPageOptions}
              rows={list.rows}
              columns={[
                { field: 'id', headerName: 'ID', width: 100 },
                { field: 'name', headerName: 'Name', width: 200 },
                { field: 'banner', headerName: 'Selected Banner', width: 220, sortable: false },
                {
                  field: 'websites',
                  headerName: 'Websites',
                  width: 250,
                  sortable: false,
                  renderCell: (params: any) => {
                    return params.row?.websites.slice(0, 3).map((web: any, i: number) => (
                      <>
                        <a href={web.url} target='_blank'>
                          {web?.name}
                        </a>
                        {i + 1 < params.row.websites.length && <span>, </span>}
                      </>
                    ));
                  }
                },
                { field: 'nrViews', headerName: 'Views', width: 80 },
                { field: 'nrClicks', headerName: 'Clicks', width: 80 },
                {
                  field: 'edit',
                  sortable: false,
                  headerName: 'Actions',
                  type: 'actions',
                  width: 150,
                  renderCell: (params: any) => {
                    return (
                      <>
                        <Link to={'/advertising/' + params.row.id}>
                          <IconButton aria-label='delete'>
                            <EditIcon />
                          </IconButton>
                        </Link>

                        <IconButton
                          aria-label='delete'
                          color={'error'}
                          onClick={() => deleteAdvertising(params.row.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    );
                  }
                }
              ]}
              showCellRightBorder
              paginationMode='server'
              pageSize={list.pageSize}
              onPageChange={(d: number) => {
                setList((prev) => ({ ...prev, page: d + 1 }));
              }}
              onPageSizeChange={(d: number) => {
                setList((prev) => ({ ...prev, page: 1, pageSize: d }));
              }}
              sortingMode='server'
              sortingOrder={['asc', 'desc']}
              onSortModelChange={(d: any) => {
                if (d && d.length > 0) {
                  setList((prev) => ({ ...prev, sort: d[0].field, asc: d[0].sort === 'asc' }));
                }
              }}
            />
          </>
        )}
        {!modal.open && (
          <Button
            variant='contained'
            onClick={() => setModal((prev) => ({ ...prev, open: true }))}
            sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
            <AddIcon /> New Ad Placement
          </Button>
        )}

        {modal.open && (
          <CreateBanner
            close={(reloadList) => {
              setModal((prev) => ({ ...prev, open: false }));
              if (reloadList) {
                getItems();
              }
            }}
          />
        )}
      </Paper>
    </Grid>
  );
};
