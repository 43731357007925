import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
interface IProps {
  handleChangeWebsite: (event: any) => any;
  value: string;
  defaultValue?: string;
  list: any;
  name: string;
  label: string;
  error: boolean;
  message: string;
  handleErrorChange: () => void;
}
export default function SelectField(props: IProps) {
  return (
    <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id='demo-simple-select-standard-label'>{props.label}</InputLabel>
      <Select
        labelId='demo-simple-select-standard-label'
        id='demo-simple-select-standard'
        value={props.value}
        name={props.name}
        onChange={(e) => props.handleChangeWebsite(e)}
        label={props.label}>
        {props.list.map((item: any) => {
          return (
            <MenuItem key={item._id} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
      {props.error ? <FormHelperText sx={{ color: '#d32f2f' }}>{props.message}</FormHelperText> : null}
    </FormControl>
  );
}
