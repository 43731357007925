import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
export const isValidURL = (url: string) => {
  const urlRegex = /^(ftp|http|https|www):\/\/[^ "]+$/;
  return urlRegex.test(url);
};

type ITypeList = 'AllowList' | 'ExcludeList';

interface UrlInputArray {
  name: ITypeList;
  label: string;
  helperText: string;
  setExcludedUrls: (urls: string[]) => void;
  urlArray: string[];
}

export const UrlInputArrayComponent = (props: UrlInputArray) => {
  const [url, setUrl] = useState('');

  const [errors, setErrors] = useState<{
    url: boolean;
    urlMessage: string;
  }>({
    url: false,
    urlMessage: ''
  });

  const { urlArray, setExcludedUrls } = props;

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const handleAddUrl = () => {
    if (!url) {
      return setErrors((prev) => ({ ...prev, url: true, urlMessage: 'Excluded url is required' }));
    }
    if (isValidURL(url) || (props.name === 'AllowList' && url === '*')) {
      setExcludedUrls(url === '*' ? [url] : [...urlArray, url]);
      setUrl('');
    } else {
      setErrors((prev) => ({ ...prev, url: true, urlMessage: 'Invalid url' }));
    }
  };

  const handleRemoveUrl = (index: number) => {
    const updatedUrlArray = urlArray.filter((_, i) => i !== index);
    setExcludedUrls(updatedUrlArray);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} md={8}>
        <TextField
          fullWidth={true}
          size='small'
          label={props.label}
          variant='outlined'
          value={url}
          onChange={handleUrlChange}
          error={errors.url}
          helperText={errors.urlMessage ? errors.urlMessage : props.helperText}
          onChangeCapture={() => setErrors((prev: any) => ({ ...prev, url: false, urlMessage: '' }))}
        />
      </Grid>

      <Grid item sm={6} md={4}>
        {props.name === 'AllowList' && urlArray?.[0] === '*' ? null : (
          <IconButton onClick={handleAddUrl}>
            <AddCircleOutlineIcon />
          </IconButton>
        )}
      </Grid>

      <Grid item sm={12} md={12}>
        {urlArray?.map((url, index) => (
          <div key={index} style={{ display: 'flex' }}>
            <p>
              Url: <b>{url}</b>
            </p>
            <IconButton onClick={() => handleRemoveUrl(index)} style={{ marginLeft: '20px' }}>
              <DeleteForeverIcon color='error' />
            </IconButton>
          </div>
        ))}
        {props.name === 'AllowList' && (
          <i>
            For allowed url's only: If value is "*" it means that the banner will be displayed in all website
            pages.
          </i>
        )}
      </Grid>
    </Grid>
  );
};
