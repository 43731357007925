import config from '../config';

interface IApiService {
  get: (token: string, queryParams: any, url: string) => any;
  post: (token: string, req: any, url: string) => any;
  postFile: (token: string, req: any, url: string) => any;
  put: (toekn: string, req: any, url: string) => any;
  patch: (toekn: string, req: any, url: string) => any;
  deleteService: (token: string, url: string) => any;
}
const get = async (token: string, queryParams: any, url: string) => {
  try {
    let query: string = '';
    if (Object.keys(queryParams).length > 0) {
      query = `?page=${queryParams.page}`;

      if (queryParams.pageSize) {
        query += `&pageSize=${queryParams.pageSize}`;
      }
      if (queryParams.sort) {
        query += `&sort=${queryParams.sort}&asc=${queryParams.asc.toString()}`;
      }
      if (queryParams.search) {
        query += `&search=${queryParams.search}`;
      }
      if (queryParams.website) {
        query += `&website=${queryParams.website}`;
      }
      if (queryParams.filters && Object.keys(queryParams.filters).length > 0) {
        query += `&filters=${queryParams.filters.type}`;
        if (queryParams.filters.start && queryParams.filters.end) {
          query += `&start=${queryParams.filters.start}&end=${queryParams.filters.end}`;
        }
      }
    }

    const response = await fetch(`${config.apiUrl}/api/${url}${query}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    });

    if (!response.ok || response.status !== 200) {
      throw new Error('NOK');
    }

    return await response.json();
  } catch (err: any) {
    throw new Error(err);
  }
};

const post = async (token: string, req: any, url: string) => {
  const response = await fetch(`${config.apiUrl}/api/${url}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  });

  const json: IAPIResponse = await response.json();
  if (!response.ok || response.status === 400) {
    if (json.message) throw new Error(json.message);
  }
  if (!response.ok || response.status !== 200) {
    throw new Error('An error occured');
  }
  return json;
};

const postFile = async (token: string, req: any, url: string) => {
  const response = await fetch(`${config.apiUrl}/api/${url}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: req
  });
  console.log({ response });
  //const json: IAPIResponse = await response.json();
  if (!response.ok) {
    switch (response.status) {
      case 400:
        throw await response.json();
      case 401:
        throw await response.json();
      case 404:
        throw await response.json();
      default:
        break;
    }
  }

  return await response.json();
};

const put = async (token: string, req: any, url: string) => {
  const response = await fetch(`${config.apiUrl}/api/${url}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  });

  const json: IAPIResponse = await response.json();
  if (!response.ok || response.status === 400) {
    if (json.message) throw new Error(json.message);
  }
  if (!response.ok || response.status !== 200) {
    throw new Error('An error occured');
  }
  return json;
};

const patch = async (token: string, req: any, url: string) => {
  const response = await fetch(`${config.apiUrl}/api/${url}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  });

  const json: IAPIResponse = await response.json();
  if (!response.ok || response.status === 400) {
    if (json.message) throw new Error(json.message);
  }
  if (!response.ok || response.status !== 200) {
    throw new Error('An error occured');
  }
  return json;
};

const deleteService = async (token: string, url: string) => {
  const response = await fetch(`${config.apiUrl}/api/${url}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const json: IAPIResponse = await response.json();
  if (!response.ok || response.status === 400) {
    if (json.message) throw new Error(json.message);
  }
  if (!response.ok || response.status !== 200) {
    throw new Error('An error occured');
  }
  return json;
};

const ApiService: IApiService = {
  get,
  post,
  postFile,
  put,
  deleteService,
  patch
};

export default ApiService;
