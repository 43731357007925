import React from 'react';
import { useState, useEffect } from 'react';
import { useUI } from '../../../context/ui';
import { useUser } from '../../../context/user';
import { showMessage } from '../../../actions/ui';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import ApiService from '../../../actions/apiService';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import InputField from '../../components/InputField';
import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import Title from '../../components/Title';
interface IError {
  server: string;
  name: boolean;
  nameMessage: string;
}

export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  const params = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState<{
    id: string;
    loading: boolean;
    name: string;
    banners: any[];
  }>({
    id: '',
    name: '',
    banners: [],
    loading: true
  });

  const [errors, setErrors] = useState<IError>({
    server: '',
    name: false,
    nameMessage: ''
  });

  const getItems = async () => {
    try {
      const query: QueryParams = {};

      const [{ item, banners }] = await Promise.all([
        ApiService.get(userContext.state.token, query, `client/${params.id}`)
      ]);

      setClient((prev) => ({
        ...prev,
        loading: false,
        banners,
        id: item ? item._id : '',
        name: item ? item.name : ''
      }));
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const save = async () => {
    //validation
    let isValid = true;
    if (!client.name) {
      setErrors((prev) => ({ ...prev, name: true, nameMessage: 'Name is required' }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const req: any = {
      name: client.name
    };

    try {
      const response = await ApiService.put(userContext.state.token, req, `client/${params.id}`);
      if (response) {
        showMessage(UIContext)('Successfully updated!', 'success');
        getItems();
      }

      return response;
    } catch (err) {
      return null;
    }
  };

  const deleteClient = async (clientId: string) => {
    const isExecuted = confirm('Are you sure to execute this action?');
    let json: IAPIResponse | null = null;
    if (isExecuted) {
      json = await ApiService.deleteService(userContext.state.token, `client/${clientId}`);
      if (json) {
        getItems().catch((err) => console.log(err));
      }
    }
    return null;
  };

  console.log({ client });

  return (
    <Card sx={{ maxWidth: '100%' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
            L
          </Avatar>
        }
        title={client.name || ''}
        subheader={`Client Id: ${client.id}`}
        action={
          <IconButton onClick={() => navigate('/client')}>
            <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
          </IconButton>
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Title> Client details</Title>
            <Divider />

            <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
              <InputField
                handleInputChange={(e: any) =>
                  setClient((prev) => ({
                    ...prev,
                    name: e.target.value
                  }))
                }
                value={client.name}
                label={'Client name'}
                name={'name'}
                error={errors.name}
                message={errors.nameMessage}
                handleErrorChange={() => setErrors((prev) => ({ ...prev, name: false, nameMessage: '' }))}
              />
            </Box>

            <Divider sx={{ mt: '2rem' }} />
            <FormControl>
              <Grid container justifyContent='end' columnGap='5px'>
                <Grid item>
                  <Button
                    sx={{ mt: '8px' }}
                    color={'error'}
                    variant='outlined'
                    startIcon={<DeleteIcon />}
                    onClick={() => deleteClient(client.id)}>
                    Delete
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    onClick={save}
                    disabled={client.loading}
                    loading={client.loading}
                    variant='outlined'
                    sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
                    <SaveIcon /> Save changes
                  </LoadingButton>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <Title>Associated banners</Title>
            <Divider />
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Views</TableCell>
                    <TableCell>Clicks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {client.banners.length > 0 &&
                    Object.values(client.banners).map((row: any) => {
                      return (
                        <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.nrViews || 0}</TableCell>
                          <TableCell>{row.nrClicks}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
