import React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DetailsModal from './modal';
import ApiService from '../../actions/apiService';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import Title from '../components/Title';
import Search from '../components/search';
export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();

  if (!userContext || !UIContext) return null;

  const [list, setList] = useState<IGridState>({
    loading: true,
    rows: [],
    totalRows: 0,
    pageSize: 10,
    rowsPerPageOptions: [10],
    totalPages: 1,
    page: 1,
    rowCount: 0,
    sort: 'name',
    asc: true,
    search: '',
    filters: {}
  });

  const getItems = async () => {
    const { search, pageSize, page, sort, asc, filters } = list;
    let json: IAPIResponse | null = null;
    try {
      const query: QueryParams = {
        page
      };

      if (pageSize) {
        query.pageSize = pageSize;
      }

      if (search) {
        query.search = search;
      }
      if (sort) {
        query.sort = sort;
        // @ts-ignore
        query.asc = asc.toString();
      }
      if (filters) {
        query.filters = filters;
      }

      json = await ApiService.get(userContext.state.token, query, `client`);
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }

    const { docs, meta } = json || {};

    setList((prev) => ({
      ...prev,
      loading: false,
      rows: json
        ? docs.map((item: any) => {
            return {
              id: item._id,
              name: item.name,
              nrBanners: item.nrBanners
            };
          })
        : [],
      rowCount: meta ? meta.total : 0,
      page: meta ? meta.page : 1,
      totalPages: meta ? meta.pages : 1
    }));
  };

  useEffect(() => {
    setList((prev) => ({ ...prev, loading: true }));
    getItems();
  }, [list.page, list.pageSize, list.search, list.asc, list.sort, list.filters]);

  const [modal, setModal] = useState<{ open: boolean; item: null | object }>({
    open: false,
    item: null
  });

  const deleteClient = async (locationId: string) => {
    const isExecuted = confirm('Are you sure to execute this action?');
    let json: IAPIResponse | null = null;
    if (isExecuted) {
      json = await ApiService.deleteService(userContext.state.token, `client/${locationId}`);
      if (json) {
        getItems().catch((err) => console.log(err));
      }
    }
    return null;
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Search
          search={(value) => {
            setList((prev) => ({ ...prev, search: value }));
          }}
          filter={(filters) => {
            setList((prev) => ({ ...prev, filters }));
          }}
        />
        <Title>Clients</Title>
        <DataGrid
          disableSelectionOnClick={true}
          autoHeight={true}
          pagination={true}
          loading={list.loading}
          rowCount={list.rowCount}
          rowsPerPageOptions={list.rowsPerPageOptions}
          rows={list.rows}
          columns={[
            { field: 'id', headerName: 'ID', width: 250 },
            { field: 'name', headerName: 'Ad client name', width: 350, sortable: false },
            { field: 'nrBanners', headerName: 'No. of active banners', width: 200, sortable: true },
            {
              field: 'edit',
              sortable: false,
              headerName: 'See details',
              type: 'actions',
              width: 200,
              renderCell: (params: any) => {
                return (
                  <>
                    <Link to={'/client/' + params.row.id}>
                      <IconButton aria-label='delete'>
                        <EditIcon />
                      </IconButton>
                    </Link>

                    <IconButton
                      aria-label='delete'
                      color={'error'}
                      onClick={() => deleteClient(params.row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                );
              }
            }
          ]}
          showCellRightBorder
          paginationMode='server'
          pageSize={list.pageSize}
          onPageChange={(d: number) => {
            setList((prev) => ({ ...prev, page: d + 1 }));
          }}
          onPageSizeChange={(d: number) => {
            setList((prev) => ({ ...prev, page: 1, pageSize: d }));
          }}
          sortingMode='server'
          sortingOrder={['asc', 'desc']}
          onSortModelChange={(d: any) => {
            if (d && d.length > 0) {
              setList((prev) => ({ ...prev, sort: d[0].field, asc: d[0].sort === 'asc' }));
            }
          }}
        />

        <Divider light />
        <Button
          variant='contained'
          onClick={() => setModal((prev) => ({ ...prev, open: true, item: null }))}
          sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
          <AddIcon /> New Client
        </Button>
        <DetailsModal
          item={modal.item}
          isOpen={modal.open}
          close={(reloadList) => {
            setModal((prev) => ({ ...prev, open: false }));
            if (reloadList) {
              getItems();
            }
          }}
        />
      </Paper>
    </Grid>
  );
};
