import React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { Close } from '@mui/icons-material';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import ApiService from '../../actions/apiService';
import Select from 'react-select';

interface FormState {
  id: string | null;
  name: string;
}

export default (props: { isOpen: boolean; item?: any | null; close: (reloadList: boolean) => void }) => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;
  useEffect(() => {
    if (props.isOpen) {
      setState((prev) => ({
        ...prev,
        id: props.item?.id || null,
        name: props.item?.name || ''
      }));
      setErrors((prev) => ({ ...prev, server: '', website: false, websiteMessage: '' }));
    }
  }, [props.isOpen]);

  const [list, setList] = useState({
    loading: true,
    rows: [],
    pageSize: 10,
    page: 1
  });

  useEffect(() => {
    setList((prev) => ({ ...prev, loading: false }));
  }, [list.page, list.pageSize]);

  const [state, setState] = useState<FormState>({
    id: null,
    name: ''
  });

  const [errors, setErrors] = useState<{ server: string; name: boolean; nameMessage: string }>({
    server: '',
    name: false,
    nameMessage: ''
  });

  const [loading, setLoading] = useState(false);

  const save = async () => {
    setErrors((prev) => ({ ...prev, uniqueId: false, uniqueIdMessage: '', server: '' }));

    const req: any = {
      name: state.name
    };

    try {
      setLoading(true);

      if (!state.id) {
        //is new
        const response = await ApiService.post(userContext.state.token, req, 'client');
        props.close(true);
        showMessage(UIContext)('Successfully added!', 'success');
        return response;
      } else {
        //is edit
        const response = await ApiService.put(userContext.state.token, req, `client/${state.id}`);
        props.close(true);
        showMessage(UIContext)('Successfully changed!', 'success');
        return response;
      }
    } catch (err) {
      setErrors((prev) => ({ ...prev, server: err.message }));
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        props.close(false);
      }}>
      <Box
        sx={{
          width: { xs: '370px', sm: '460px' },
          bgcolor: '#ffffff',
          boxShadow: '24px',
          padding: '32px',
          borderRadius: 4,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography component='h2' variant='h6' sx={{ color: '#222326' }}>
            {state.id ? 'Update client' : 'Create client'}
          </Typography>
          <IconButton onClick={() => props.close(false)}>
            <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
          </IconButton>
        </Box>
        <Box>{errors.server ? <Alert severity='error'>{errors.server}</Alert> : null}</Box>
        <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
          <TextField
            onChange={(e) => setState((prev) => ({ ...prev, name: e.target.value }))}
            variant='standard'
            required
            value={state.name}
            fullWidth
            label='Name'
            name='name'
          />

          <LoadingButton
            onClick={save}
            disabled={loading}
            loading={loading}
            variant='contained'
            sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
            {state.id ? 'Modifica' : 'Adauga'}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
