import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Logout } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import { useNavigate, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import { logout } from './actions/user';
import { useUser } from './context/user';
import { useUI } from './context/ui';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WebIcon from '@mui/icons-material/Web';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import PeopleIcon from '@mui/icons-material/People';
function Copyright(props: any) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://ithadvertisingdashboard.fullscreendigital.ro/'>
        Fullscreen Digital.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    })
  }
}));

const mdTheme = createTheme();

export default (props: any) => {
  const [open, setOpen] = React.useState(true);
  const userContext: any = useUser();
  const UIContext = useUI();

  if (!userContext || !UIContext) return null;

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const setLocationName = () => {
    if (path === '/') {
      return 'Dashboard';
    }
    if (path === '/banner') {
      return 'Banners';
    }

    if (path === '/client') {
      return 'Ad clients';
    }
    if (path.includes('/client/')) {
      return 'Client details';
    }

    if (path === '/location') {
      return 'Locations';
    }
    if (path === '/website') {
      return 'Websites';
    }
    if (path === '/advertising') {
      return 'Ad placement';
    }
    if (path.includes('/advertising/')) {
      return 'Ad placement details';
    }
    if (path.includes('/location/')) {
      return 'Location details';
    }
    if (path.includes('/website/')) {
      return 'Website details';
    }
  };

  const changeLocation = (pathName: string) => {
    navigate(pathName);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      {UIContext.state.isLoading ? (
        <Box sx={{ width: '100%', position: 'absolute', top: '0' }}>
          <LinearProgress />
        </Box>
      ) : null}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='absolute' open={open}>
          <Toolbar
            sx={{
              pr: '24px' // keep right padding when drawer closed
            }}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' })
              }}>
              <MenuIcon />
            </IconButton>
            <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
              {setLocationName()}
            </Typography>
            <IconButton color='inherit'>
              <Box>
                <img src={'logo_tiriac.png'} style={{ height: '2rem' }} />
              </Box>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant='permanent' open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1]
            }}>
            <Typography sx={{ marginRight: '45px' }} component='h1' variant='h6' color='inherit'>
              Tiriac Group
            </Typography>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <div>
              <ListItem
                button
                onClick={() => changeLocation('/')}
                sx={{ backgroundColor: path === '/' ? 'aliceblue' : '' }}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
              </ListItem>
              <ListItem
                button
                onClick={() => changeLocation('/client')}
                sx={{ backgroundColor: path === '/client' ? 'aliceblue' : '' }}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary='Ad clients' />
              </ListItem>

              <ListItem
                button
                onClick={() => changeLocation('/banner')}
                sx={{ backgroundColor: path.includes('/banner') ? 'aliceblue' : '' }}>
                <ListItemIcon>
                  <FeaturedVideoIcon />
                </ListItemIcon>
                <ListItemText primary='Banners' />
              </ListItem>

              <ListItem
                button
                onClick={() => changeLocation('/website')}
                sx={{ backgroundColor: path.includes('/website') ? 'aliceblue' : '' }}>
                <ListItemIcon>
                  <WebIcon />
                </ListItemIcon>
                <ListItemText primary='Websites' />
              </ListItem>

              <ListItem
                button
                onClick={() => changeLocation('/location')}
                sx={{ backgroundColor: path.includes('/location') ? 'aliceblue' : '' }}>
                <ListItemIcon>
                  <EditLocationAltIcon />
                </ListItemIcon>
                <ListItemText primary='Locations' />
              </ListItem>

              <ListItem
                button
                onClick={() => changeLocation('/advertising')}
                sx={{ backgroundColor: path.includes('/advertising') ? 'aliceblue' : '' }}>
                <ListItemIcon>
                  <ViewCarouselIcon />
                </ListItemIcon>
                <ListItemText primary='Ad placement' />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  logout(userContext)();
                  navigate('/login');
                }}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary='Logout' />
              </ListItem>
            </div>
          </List>
        </Drawer>
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
          }}>
          <Toolbar />
          <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
            {props.children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
