import React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DataGrid } from '@mui/x-data-grid';
import ApiService from '../../actions/apiService';
import Title from '../components/Title';
import Search from './search';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import CrateModal from './modal';

const XLSX = require('xlsx');
export default () => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;

  const [modal, setModal] = useState<{ open: boolean; item: null | object }>({
    open: false,
    item: null
  });

  const [list, setList] = useState<IGridState>({
    loading: true,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100],
    pageSize: 100,
    page: 1,
    rowCount: 0,
    sort: 'name',
    asc: true,
    search: '',
    filters: {}
  });

  const getItems = async () => {
    const { search, pageSize, page, sort, asc, filters } = list;

    let json: IAPIResponse | null = null;
    try {
      const query: QueryParams = {
        page: page
      };

      if (pageSize) {
        query.pageSize = pageSize;
      }

      if (sort) {
        query.sort = sort;
        // @ts-ignore
        query.asc = asc.toString();
      }

      if (search) {
        query.search = search;
      }
      if (filters) {
        query.filters = filters;
      }
      json = await ApiService.get(userContext.state.token, query, 'website');
    } catch (err) {
      showMessage(UIContext)('An error occured!', 'error');
    }

    setList((prev) => ({
      ...prev,
      loading: false,
      rows: json
        ? json.docs.map((item: any) => {
            return {
              id: item._id,
              name: item.name,
              url: item.url,
              locations: item.locations,
              advertising: item.advertising,
              nrViews: item.nrViews,
              nrClicks: item.nrClicks
            };
          })
        : [],
      rowCount: json?.meta ? json?.meta.total : 0
    }));
  };

  useEffect(() => {
    setList((prev) => ({ ...prev, loading: true }));
    getItems();
  }, [list.page, list.pageSize, list.sort, list.search, list.asc, list.filters]);

  const exportWebsiteDetails = () => {
    const toexport: any = [];
    list.rows.forEach((item: any) => {
      toexport.push({
        name: item.name,
        url: item.url,
        locations: item.locations,
        advertisements: item.advertising,
        totalViews: item.nrViews,
        totalClicks: item.nrClicks
      });
    });

    const websiteD: any = XLSX.utils.json_to_sheet(toexport);
    const wb: any = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, websiteD, 'website_details');
    return XLSX.writeFile(wb, 'website_details.xlsx');
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Search
          search={(value) => {
            setList((prev) => ({ ...prev, search: value }));
          }}
        />
        <Title>Websites</Title>
        <DataGrid
          disableSelectionOnClick={true}
          autoHeight={true}
          pagination={true}
          loading={list.loading}
          rowCount={list.rowCount}
          rowsPerPageOptions={list.rowsPerPageOptions}
          rows={list.rows}
          columns={[
            { field: 'id', headerName: 'ID', width: 80 },
            { field: 'name', headerName: 'Website name', width: 200, sortable: false },
            { field: 'url', headerName: 'Url', width: 250, sortable: false },
            { field: 'locations', headerName: 'Locations', width: 100 },
            { field: 'advertising', headerName: 'Advertisements', width: 150 },
            { field: 'nrViews', headerName: 'Views', width: 100 },
            { field: 'nrClicks', headerName: 'Clicks', width: 100 },
            {
              field: 'edit',
              sortable: false,
              headerName: 'See details',
              type: 'actions',
              width: 130,
              renderCell: (params: any) => {
                return (
                  <>
                    <Link to={'/website/' + params.row.id + '/update'}>
                      <IconButton aria-label='delete'>
                        <EditIcon />
                      </IconButton>
                    </Link>
                    <Link to={'/website/' + params.row.id}>
                      <IconButton>
                        <VisibilityIcon />
                      </IconButton>
                    </Link>
                  </>
                );
              }
            }
          ]}
          showCellRightBorder
          paginationMode='server'
          pageSize={list.pageSize}
          onPageChange={(d: number) => {
            setList((prev) => ({ ...prev, page: d + 1 }));
          }}
          onPageSizeChange={(d: number) => {
            setList((prev) => ({ ...prev, page: 1, pageSize: d }));
          }}
          //sortingMode='server'
          sortingOrder={['asc', 'desc']}
          onSortModelChange={(d: any) => {
            if (d && d.length > 0) {
              setList((prev) => ({ ...prev, sort: d[0].field, asc: d[0].sort === 'asc' }));
            }
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant='contained'
            onClick={() => exportWebsiteDetails()}
            sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px', mr: '10px' }}>
            <FileDownloadIcon /> Export Details
          </Button>
          <Button
            variant='contained'
            onClick={() => setModal((prev) => ({ ...prev, open: true, item: null }))}
            sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
            <AddIcon /> New Website
          </Button>
        </div>
        <CrateModal
          isOpen={modal.open}
          close={(reloadList) => {
            setModal((prev) => ({ ...prev, open: false }));
            if (reloadList) {
              getItems();
            }
          }}
        />
      </Paper>
    </Grid>
  );
};
