import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
interface IProps {
  handleInputChange: (event: any) => any;
  value: string;
  name: string;
  label: string;
  error: boolean;
  message: string;
  handleErrorChange: () => void;
}
export default function InputField(props: IProps) {
  return (
    <FormControl variant='standard' sx={{ m: 1, minWidth: 200 }}>
      <TextField
        onChange={(e) => props.handleInputChange(e)}
        variant='standard'
        required
        value={props.value}
        fullWidth
        label={props.label}
        name={props.name}
        error={props.error}
        helperText={props.message}
        onChangeCapture={() => props.handleErrorChange()}
      />
    </FormControl>
  );
}
