import React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputField from '../components/InputField';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { Close } from '@mui/icons-material';
import { useUI } from '../../context/ui';
import { useUser } from '../../context/user';
import { showMessage } from '../../actions/ui';
import ApiService from '../../actions/apiService';
import { UrlInputArrayComponent } from './components/UrlInputArray';

const isValidUrl = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
};

export default (props: { isOpen: boolean; close: (reloadList: boolean) => void }) => {
  const userContext: any = useUser();
  const UIContext = useUI();
  if (!userContext || !UIContext) return null;

  const [website, setWebsite] = useState<{
    loading: boolean;
    name: string;
    url: string;
    excludedUrlArray: string[];
    allowList: string[];
  }>({
    name: '',
    url: '',
    loading: true,
    excludedUrlArray: [],
    allowList: []
  });

  const [errors, setErrors] = useState<{
    server: string;
    name: boolean;
    nameMessage: string;
    url: boolean;
    urlMessage: string;
  }>({
    server: '',
    name: false,
    nameMessage: '',
    url: false,
    urlMessage: ''
  });

  const [loading, setLoading] = useState(false);

  const save = async () => {
    let isValid = true;
    if (!website.name) {
      setErrors((prev) => ({ ...prev, name: true, nameMessage: 'Name is required' }));
      isValid = false;
    }
    if (!website.url) {
      setErrors((prev) => ({ ...prev, url: true, urlMessage: 'Website is required' }));
      isValid = false;
    }

    if (!isValidUrl(website.url)) {
      setErrors((prev) => ({ ...prev, url: true, urlMessage: 'Invalid url' }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    const req: any = {
      name: website.name,
      url: website.url,
      excludedUrl: website.excludedUrlArray,
      allowList: website.allowList
    };

    try {
      const response = await ApiService.post(userContext.state.token, req, 'website');
      if (response) {
        props.close(true);
        showMessage(UIContext)('Successfully added!', 'success');
        props.close(true);
        setWebsite((prev: any) => ({ ...prev, name: '', url: '' }));
      } else {
        props.close(true);
        showMessage(UIContext)('An error happened. Please notify administrator!', 'error');
      }
    } catch (err) {
      setErrors((prev) => ({ ...prev, server: err.message }));
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        props.close(false);
      }}>
      <Box
        sx={{
          width: { xs: '370px', sm: '460px', md: '500px', lg: '600px' },
          bgcolor: '#ffffff',
          boxShadow: '24px',
          padding: '32px',
          borderRadius: 4,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography component='h2' variant='h6' sx={{ color: '#222326' }}>
            Create website
          </Typography>
          <IconButton onClick={() => props.close(false)}>
            <Close sx={{ alignSelf: 'center', color: '#AAACB3' }} />
          </IconButton>
        </Box>
        <Box>{errors.server ? <Alert severity='error'>{errors.server}</Alert> : null}</Box>
        <Box sx={{ minWidth: 120, mt: '16px', rowGap: '16px' }} display='flex' flexDirection='column'>
          <InputField
            handleInputChange={(e: any) => setWebsite((prev) => ({ ...prev, name: e.target.value }))}
            value={website.name}
            label='Website name'
            name='name'
            error={errors.name}
            message={errors.nameMessage}
            handleErrorChange={() => setErrors((prev: any) => ({ ...prev, name: false, nameMessage: '' }))}
          />
          <InputField
            handleInputChange={(e: any) => setWebsite((prev) => ({ ...prev, url: e.target.value }))}
            value={website.url}
            label='Website url'
            name='url'
            error={errors.url}
            message={errors.urlMessage}
            handleErrorChange={() => setErrors((prev: any) => ({ ...prev, url: false, urlMessage: '' }))}
          />

          <UrlInputArrayComponent
            name='AllowList'
            label='Enter allowed URL'
            helperText={`This url should match the exact url where the banner will be displayed or an "*"`}
            urlArray={website.allowList}
            setExcludedUrls={(urls: string[]) => {
              setWebsite((prevData) => ({ ...prevData, allowList: urls }));
            }}
          />

          <UrlInputArrayComponent
            name='ExcludeList'
            label='Enter excluded URL'
            helperText='This url should match the exact url where the banner should not be displayed'
            urlArray={website.excludedUrlArray}
            setExcludedUrls={(urls: string[]) => {
              setWebsite((prevData) => ({ ...prevData, excludedUrlArray: urls }));
            }}
          />

          <LoadingButton
            onClick={save}
            disabled={loading}
            loading={loading}
            variant='contained'
            sx={{ alignSelf: 'flex-end', mt: '8px', minWidth: '80px' }}>
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
